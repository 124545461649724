import React from "react";
import { Container, Grid, IconButton, Paper, TextField } from "@mui/material";
import "./index.css";
import AddQuestion from "../../Components/Form/AddQuestion";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import EditFormQuestion from "./EditFormQuestion";

function Form({
  deleteQuestion,
  deleteOption,
  categoryIndex,
  category,
  setForm,
  categories,
  form,
  deleteCategory,
}) {
  const addOption = (questionId) => {
    let item = form.categories[categoryIndex];
    item.questions.map((x, index) => {
      if (questionId === index) {
        x.choices.push({ option: "" });
      }
      return x;
    });
    let newSections = [...categories];
    newSections[categoryIndex] = item;
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  const addQuestion = () => {
    let item = form.categories[categoryIndex];
    let test = {
      text: "",
      description: "",
      question_type: "SC",
      question_class: "N",
      required: true,
      is_horizontal: false,
      choices: [
        {
          option: "",
          points: 0,
        },
      ],
    };
    item.questions.push(test);
    let newSections = [...categories];
    newSections[categoryIndex] = item;
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  const handleOptionValue = (e, qIndex, position) => {
    const value = e.target.value;
    const name = e.target.name;
    let item = form.categories[categoryIndex];
    let question = {};
    question = item.questions[qIndex];
    question.choices[position][name] = value;
    let newQuestions = [...item.questions];
    newQuestions[qIndex] = question;
    let newSections = [...categories];
    newSections[categoryIndex] = item;
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  const handleQuestionValue = (name, value, position) => {
    let item = form.categories[categoryIndex];
    let question = {};
    question = item.questions[position];
    question[name] = value;
    let newQuestions = [...item.questions];
    newQuestions[position] = question;
    let newSections = [...categories];
    newSections[categoryIndex] = item;
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  const handleQuestion = (e, position) => {
    const name = e.target.name;
    const value = e.target.value;
    handleQuestionValue(name, value, position);
  };

  const handleQuestionType = (e, position) => {
    const name = e.target.name;
    const value = e.target.value;
    handleQuestionValue(name, value, position);
  };

  const handleType = (e, position) => {
    const name = e.target.name;
    const value = e.target.value;
    handleQuestionValue(name, value, position);
    if (value === "OT") {
      let item = category.questions[position];
      item["choices"] = [];
      let newQuestions = [...category.questions];
      newQuestions[position] = item;
    }
  };

  const handleChange = (position, val) => {
    let item = form.categories[categoryIndex];
    let question = {};
    question = item.questions[position];
    question[val] = !question[val];
    let newQuestions = [...item.questions];
    newQuestions[position] = question;
    let newSections = [...categories];
    newSections[categoryIndex] = item;
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  const handleCategory = (e) => {
    let item = form.categories[categoryIndex];
    item[e.target.name] = e.target.value;
    let newSections = [...categories];
    newSections[categoryIndex] = item;
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={8} className="mx-auto">
            <Paper className="px-4 pb-4 pt-3 headind__card" elevation={5}>
              <TextField
                name="kind"
                className="form__cat"
                placeholder="Category"
                variant="standard"
                value={category.kind}
                onChange={(e) => handleCategory(e)}
                fullWidth
              />
              <TextField
                name="description"
                className="form__cat_desc mb-2"
                placeholder="Description"
                variant="standard"
                value={category.description}
                onChange={(e) => handleCategory(e)}
                fullWidth
              />
              <TextField
                variant="standard"
                type="number"
                name="weightage_in_percentage"
                placeholder="Enter weightage"
                value={category.weightage_in_percentage}
                onChange={(e) => handleCategory(e)}
                fullWidth
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={() => deleteCategory(categoryIndex)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {category.questions.map((question, index) => (
          <Grid key={index} container>
            <Grid item xs={8} className="mx-auto mt-3">
              <EditFormQuestion
                question={question}
                key={index + "new"}
                handleChange={handleChange}
                formIndex={index}
                addOption={addOption}
                handleQuestion={handleQuestion}
                handleQuestionType={handleQuestionType}
                handleType={handleType}
                handleOptionValue={handleOptionValue}
                categoryIndex={categoryIndex}
                deleteQuestion={deleteQuestion}
                deleteOption={deleteOption}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="flex-end" direction="row">
          <Grid item>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box>
                <AddQuestion addQuestion={addQuestion} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Form;
