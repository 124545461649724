import React from "react";
import { Button, Grid } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

function AddQuestion({ addQuestion }) {
  return (
    <Grid container>
      <Grid item xs={0}>
        <Button
          variant="contained"
          onClick={() => addQuestion()}
          startIcon={<ControlPointIcon />}
        >
          Question
        </Button>
      </Grid>
    </Grid>
  );
}

export default AddQuestion;
