import React from "react";
import MatterLogo from "../assets/Matter-logo-name-small.svg";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import classes from "./Layout.module.css";

const ResponseLayout = () => {
  return (
    <>
      <Grid container alignItems="center" className={classes.container_margins}>
        <Grid item xs={3} sm={2} md={2} textAlign="right">
          <img src={MatterLogo} alt="matter-logo" className={classes.logo} />
        </Grid>
        <Grid item xs={8} sm={8} md={8} textAlign="center"></Grid>
      </Grid>
      <Outlet />
    </>
  );
};

export default ResponseLayout;
