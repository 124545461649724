import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { getAllForm } from "../../Apis/Form/GetAllForms";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { deleteForm } from "../../Apis/Form/DeleteForm";
import { searchForm } from "../../Apis/Form/SearchForm";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopylUrl } from "../../Utils/BaseUrl/BaseUrl";
import { InitialContext } from "../../Utils/Context/InitailContext";
import { debounce } from "lodash";
import SkeletonLoading from "../../Components/Skeleton";

const Landing = () => {
  const [forms, setForms] = useState([]);
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  const initial = useContext(InitialContext);

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  const getAllForms = async () => {
    let user = localStorage.getItem("user");
    if (user) {
      console.log({ user });
      const data = await getAllForm();
      if (data.length === undefined) {
        initial.setSnacky({
          color: "error",
          message: data.message,
          open: true,
        });
        setForms([]);
        setLoading(false);
      } else if (data.length === 0) {
        initial.setSnacky({
          color: "info",
          message: "No forms available",
          open: true,
        });
        setLoading(false);
      } else if (data.length > 0) {
        setForms(data);
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setKey("");
  };
  const handleShow = (formKey) => {
    setKey(formKey);
    setOpen(true);
  };

  const handleDelete = async () => {
    const data = await deleteForm(key, initial);
    if (data) {
      setLoading(true);
      getAllForms();
      setKey("");
      setOpen(false);
    }
  };

  const copyToClipBoard = async (key) => {
    try {
      console.log(CopylUrl);
      await navigator.clipboard.writeText(`${CopylUrl}register/${key}`);
      initial.setSnacky({
        color: "success",
        message: "Successfully Copied!",
        open: true,
      });
    } catch (err) {
      initial.setSnacky({
        color: "error",
        message: "Failed to copy",
        open: true,
      });
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    history("/login");
  };

  useEffect(() => {
    getAllForms();
  }, []);

  const searchFunc = useRef(
    debounce(async (value) => {
      const data = await searchForm(value);
      if (data.error) {
        initial.setSnacky({
          color: "error",
          message: `${data.message}`,
          open: true,
        });
        setForms([]);
      } else {
        setForms(data);
      }
    }, 800)
  ).current;

  return (
    <>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openFeedBack}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={severity}>{feedBack}</Alert>
      </Snackbar> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this form?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item md={8} className="mx-auto mt-3 ">
          <Grid container className="d-flex justify-content-between">
            <Grid item md={5}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  autoFocus
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    searchFunc(e.target.value);
                  }}
                  value={searchQuery}
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className="mb-2 me-1"
                onClick={handleLogout}
              >
                Log out
              </Button>
              <Button
                variant="contained"
                className="mb-2"
                onClick={() => history("/create-form")}
              >
                Create Form
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <SkeletonLoading
              class="px-4 pb-4 pt-3 my-2 headind__card"
              height="25vh"
            />
          ) : (
            <>
              {forms.length === 0 ? (
                <Paper
                  className="px-4 pb-4 pt-3 my-2 headind__card"
                  elevation={5}
                >
                  No records found
                </Paper>
              ) : (
                forms.map((form, index) => {
                  return (
                    <Paper
                      key={index}
                      className="px-4 pb-4 pt-3 my-2 headind__card"
                      elevation={5}
                    >
                      <Grid container>
                        <Grid item xs={11}>
                          <Typography display="block" variant="h5">
                            {form.name}
                          </Typography>
                          <Typography display="block">
                            {form.description}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className="d-flex justify-content-center flex-column"
                        >
                          <Tooltip title="Delete" placement="right">
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleShow(form.key)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Edit"
                            placement="right"
                            onClick={() => history(`/edit/${form.key}`)}
                          >
                            <IconButton aria-label="edit">
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="All Responses"
                            placement="right"
                            onClick={() => history(`/responses/${form.key}`)}
                          >
                            <IconButton aria-label="All responses">
                              <ArrowRightAltIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Copy Link" placement="right">
                            <IconButton
                              aria-label="copy"
                              onClick={() => copyToClipBoard(form.key)}
                            >
                              <ContentCopyIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Paper>
                  );
                })
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Landing;
