import { forwardRef, useImperativeHandle } from "react";
import "./Progress.css";
import {
  setCategoryWidth,
  setPercentAge,
  setPrevious,
} from "../../Store/Reducers/ConstantSlice";
import { toPascalCase } from "../../Utils/Helpers/helpers";
import { useSelector, useDispatch } from "react-redux";
import { ProgressComponent, LastProgress } from "../ProgressWrapperComponent";
import UseHeightHook from "../../Utils/Hooks/UseHeightHook";
import UseWidthHook from "../../Utils/Hooks/UseWidthHook";

const Progress = (
  { stages, questionIndex, categoryIndex, progressData },
  ref
) => {
  const dispatch = useDispatch();

  const {
    categoryColors,
    categoryLightColors,
    categoryWidth,
    percentage,
    previous,
  } = useSelector((state) => state?.constantData);

  // console.log(percentage,'this is the current percent age')
  useImperativeHandle(ref, () => {
    return {
      handleStyleUpdate: (srt) => handleStyleUpdate(srt),
    };
  });

  function handleStyleUpdate(srt) {
    let index = stages[categoryIndex].kind.toLowerCase();
    let val = stages[categoryIndex];
    let currentPercent = 0;
    const hundredPercent = 100;
    const updatedStyles = { ...categoryWidth };

    if (index !== previous) {
      dispatch(setPrevious(index));
      if (srt === "next") {
        currentPercent = 1 + hundredPercent / val.questions.length;
        updatedStyles[index] = currentPercent;
      }
      if (srt === "back") {
        updatedStyles[index] = hundredPercent;
        index = stages[categoryIndex + 1].kind.toLowerCase();
        updatedStyles[index] = currentPercent;
      }
      dispatch(setCategoryWidth(updatedStyles));
      dispatch(setPercentAge(currentPercent));
    } else {
      if (srt === "back") {
        currentPercent =
          updatedStyles[index] - hundredPercent / val.questions.length;
      } else {
        currentPercent = percentage + hundredPercent / val.questions.length;
      }
      updatedStyles[index] = currentPercent;
      dispatch(setCategoryWidth(updatedStyles));
      dispatch(setPercentAge(currentPercent));
    }
  }
  const totalQuestions = stages.reduce(
    (total, category) => total + category.questions.length,
    0
  );
  let progressHeight = "0px";
  const windowWidth = UseWidthHook();
  // let constWidth = 0;
  let perQuestionWidth = 0;

  if (windowWidth < 768) {
    perQuestionWidth = Math.round(450 / totalQuestions) - 2;
    progressHeight = "20px";
  } else if (windowWidth < 1024) {
    perQuestionWidth = Math.round(550 / totalQuestions) - 1;
    progressHeight = "24px";
  } else if (windowWidth < 1280) {
    perQuestionWidth = Math.round(750 / totalQuestions) - 1;
    progressHeight = "28px";
  } else if (windowWidth < 1440) {
    perQuestionWidth = Math.round(750 / totalQuestions) - 1;
    progressHeight = "30px";
  } else {
    perQuestionWidth = Math.round(950 / totalQuestions) - 1;
    progressHeight = "32px";
  }

  // console.log("Number of questions:", perQuestionWidth,{windowWidth,progressHeight});

  return (
    <>
      <div className="font_style">
        {stages.map((stage, index) => (
          <ProgressComponent
            key={index}
            style={{}}
            backgroundColor={`${categoryColors[stage.kind.toLowerCase()]}`}
            backgroundColorLight={`${
              categoryLightColors[stage.kind.toLowerCase()]
            }`}
            questionIndex={questionIndex}
            categoryIndex={categoryIndex}
            stages={stages}
            index={index}
            width={stage.questions.length * perQuestionWidth}
            height={progressHeight}
          >
            <div
              style={{
                color: `${index === categoryIndex ? "#000" : "#fff"}`,
              }}
            >
              <p className="progress-kind">{toPascalCase(stage.kind)}</p>
            </div>
            {stages.length - 1 === index ? (
              <LastProgress
                backgroundColor={`${categoryColors[stage.kind.toLowerCase()]}`}
                backgroundColorLight={`${
                  categoryLightColors[stage.kind.toLowerCase()]
                }`}
                questionIndex={questionIndex}
                categoryIndex={categoryIndex}
                stages={stages}
                index={index}
                width={stage.questions.length * perQuestionWidth}
                className={`progress-bar ${
                  stages.length - 1 === index && "last-progress"
                } `}
                style={{
                  backgroundColor: `${
                    categoryLightColors[stage.kind.toLowerCase()]
                  }`,
                  width: `${stage.questions.length * perQuestionWidth}px`,
                  transform: "skew(-25deg)",
                  borderRadius: "0px",
                  borderTopRightRadius: "5px",
                }}
                height={progressHeight}
              >
                <div
                  className="progress"
                  style={{
                    backgroundColor: `${
                      categoryColors[stage.kind.toLowerCase()]
                    }`,
                    width: `${categoryWidth[stage.kind.toLowerCase()]}%`,
                    borderRadius: "0px",
                    borderTopRightRadius: "5px",
                  }}
                ></div>
              </LastProgress>
            ) : (
              <div
                data-content={`${
                  stages.length - 1 === index &&
                  categoryWidth[stage.kind.toLowerCase()] >= 100
                    ? categoryColors[stage.kind.toLowerCase()]
                    : categoryLightColors[stage.kind.toLowerCase()]
                }`}
                className={`progress-bar`}
                style={{
                  backgroundColor: `${
                    categoryLightColors[stage.kind.toLowerCase()]
                  }`,
                  width: `${stage.questions.length * perQuestionWidth}px`,
                  transform: "skew(-25deg)",
                }}
              >
                <div
                  className="progress"
                  style={{
                    backgroundColor: `${
                      categoryColors[stage.kind.toLowerCase()]
                    }`,
                    width: `${categoryWidth[stage.kind.toLowerCase()]}%`,
                    borderRadius: "0px",
                  }}
                ></div>
              </div>
            )}
          </ProgressComponent>
        ))}
      </div>
    </>
  );
};

export default forwardRef(Progress);
