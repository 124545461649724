import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./Store/store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IContext } from "./Utils/Context/InitailContext";
import "./fonts/NexaBold/Nexa_Bold.otf";
import "./fonts/NexaBook/Nexa_Book.otf";
import "./fonts/NexaLight/Nexa_Light.otf";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { checkCookie } from "./Utils/CookieFunction/CookieFunction";
let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
setInterval(() => {
  checkCookie(persistor);
}, 60 * 1000);

const GTMScript = () => (
  <script
    async
    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_MEASUREMENT_ID}`}
  />
);

const initGtag = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }

  gtag("js", new Date());
  gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID);
};

root.render(
  <React.StrictMode>
    <GTMScript />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IContext>
          <App />
        </IContext>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

initGtag();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
