import React, { useState } from "react";
import "./HorizontalRadio.css";

import { Grid, Paper } from "@mui/material";

const HorizontalRadio = (props) => {
  const [checkedParagraphId, setCheckedParagraphId] = useState(null);
  const handleRadioChange = (event, option) => {
    let paragraphId = option.id;
    if (checkedParagraphId === paragraphId) {
      setCheckedParagraphId(null);
    } else {
      setCheckedParagraphId(paragraphId);
    }
    console.log(option, event.target.value);
    props.onChange(paragraphId, props.categoryIndex, props.questionIndex);
  };

  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const half = Math.ceil(props.options.length / 2);
  return (
    <>
      <div>
        <div className="radio-container text-class">
          <Grid container spacing={0} className="scrollable-div">
            {props.options.map((option, index) => (
              <Grid
                item
                xs={12 / props.options.length}
                sm={12 / props.options.length}
                md={12 / props.options.length}
                key={option.id}
              >
                <Paper elevation={0}>
                  <div className="text-container">
                    <div
                      className={`divider2`}
                      style={{ backgroundColor: `${index === 0 && "#FFF"}` }}
                    ></div>
                    <div className="input-div">
                      <input
                        className="horizontal_input"
                        type="radio"
                        id={option.id}
                        name={props.label}
                        value={option.points}
                        checked={props.answer ? props.answer === option.id : ""}
                        onChange={(e) => handleRadioChange(e, option)}
                      />
                    </div>
                    <div
                      className="divider"
                      style={{
                        backgroundColor: `${
                          index === props.options.length - 1 && "#FFF"
                        }`,
                      }}
                    ></div>
                  </div>
                  <p className="webkit_center">{index + 1}</p>
                  <div>
                    {option.option.length !== 1 && (
                      <label
                        className={
                          checkedParagraphId === option.id
                            ? "radio_label_checked"
                            : "radio_label"
                        }
                        style={{
                          marginLeft: "0",
                          textAlign: `${index < half ? "left" : "right"}`,
                        }}
                      >
                        {
                          option.option
                          // .substring(2, option.option.length + 1)
                        }
                      </label>
                    )}
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
};
//TODO: implement to the only first and last
//(index === 0 || index === props.options.length - 1)

export default HorizontalRadio;
