import axios from "axios";
import { BaseUrl } from "../Utils/BaseUrl/BaseUrl";

export const createStartup = async (payload) => {
  try {
    const { data } = await axios.post(`${BaseUrl}/startup/create`, payload);
    if (data) {
      data.success = true;
      return data;
    }
  } catch (error) {
    error.success = false;
    console.log(error);
    return error;
  }
};
