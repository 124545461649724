import React from "react";
import { Box, Grid } from "@mui/material";
import MatterLogo from "../../assets/Matter-logo-name.svg";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./Register.module.css";
import background from "../../assets/background_start.svg";
// import TagManager from 'react-gtm-module'
import { AnalyticEvents } from "../../Utils/Helpers/helpers";
function Register() {
  const history = useNavigate();
  let { key } = useParams();

  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
  };

  const handleClick = () => {
    AnalyticEvents(
      "Registration",
      "Registration Started",
      "this is my label testing the thi "
    );
    history(`/register/form/${key}`);
  };

  return (
    <div style={backgroundStyle}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.register_heading_div}
      >
        <Grid item className={classes.main_logo_wrapper}>
          <img
            src={MatterLogo}
            alt="Matter-logo-name"
            className={classes.main_logo}
          />
        </Grid>
      </Grid>
      <Grid className={classes.text_padding}>
        <Grid item xs={3}>
          <p className={classes.txt_maturity}> {/* Maturity Assessment */} </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.register_button_div}
      >
        <Grid>
          <Box>
            <button className={classes.btn_register_one} onClick={handleClick}>
              Start
            </button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Register;
