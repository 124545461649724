import instance from "../Utils/Axios/instance";

export const StartupScore = async (payload) => {
  if (payload !== undefined) {
    try {
      const { data } = await instance.get(`/total/${payload}`);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
      error.error = true;
      return error;
    }
  }
};

export const StartupGoals = async (payload) => {
  if (payload !== undefined) {
    try {
      const { data } = await instance.post(
        `/roadmap/resources-detail/`,
        payload
      );
      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
      error.error = true;
      return error;
    }
  }
};

export const getFilters = async (content, category, id) => {
  let url = "";
  if (content !== "" && category !== "") {
    url =
      "/roadmap/resources-detail/" +
      id +
      "/?" +
      content +
      "&resource_program=" +
      category;
  } else if (category !== "") {
    url = "/roadmap/resources-detail/" + id + "/?resource_program=" + category;
  } else {
    url = "/roadmap/resources-detail/" + id + "/?" + content;
  }
  try {
    const { data } = await instance.get(url);
    if (data) {
      return data;
    }
  } catch (error) {
    console.log(error);
    error.error = true;
    return error;
  }
};

export const generalPatchRequest = async (requestType, resource_id, id) => {
  let url = "";
  let payload = {};
  let data;

  console.log(requestType, id, resource_id);
  try {
    if (requestType === "bookmarked") {
      payload = { bookmarked: "true" };
      url = "/roadmap/resources-detail/" + id + "/" + resource_id + "/";
      data = await instance.patch(url, payload);
      if (data) {
        console.log(data, "data-------------bookmarked");

        return data.data;
      }
    } else if (requestType === "completed") {
      payload = { completed: "true" };
      url = "/roadmap/resources-detail/" + id + "/" + resource_id + "/";
      data = await instance.patch(url, payload);
      if (data) {
        console.log(data, "data-------------patch");

        return data.data;
      }
    } else if (requestType === "deleted") {
      url = "/roadmap/resources-detail/" + id + "/" + resource_id + "/";
      data = await instance.delete(url);
      if (data) {
        console.log(data, "data-------------delete");
        return data.data;
      } else return null;
    }
  } catch (error) {
    console.log(error);
    error.error = true;
    return error;
  }
};

export const searchFilters = async (keyword, id) => {
  let url = "/roadmap/resources-detail/" + id + "/?keyword=" + keyword;

  try {
    const { data } = await instance.get(url);
    if (data) {
      return data;
    }
  } catch (error) {
    console.log(error);
    error.error = true;
    return error;
  }
};
