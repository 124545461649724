import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SendIcon from "@mui/icons-material/Send";
import Question from "./Question";
import { createForm } from "../../Apis/Form/CreateForm";
import { useNavigate } from "react-router-dom";
import { InitialContext } from "../../Utils/Context/InitailContext";

const Section = () => {
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [oversellThreshold, setOversellThreshold] = useState("");
  const [oversellPercentage, setOversellPercentage] = useState("");
  const [percentageDecrease, setPercentageDecrease] = useState("");
  const [questionCount, setQuestionCount] = useState(1);
  const [oversellQuestionCount, setOversellQuestionCount] = useState(0);
  const [sections, setSections] = useState([
    {
      form_category: "",
      category_weightage: 50,
      category_description: "",
      questions: [
        {
          question_text: "",
          question_description: "",
          type: "single_choice",
          q_type: "normal",
          is_required: true,
          is_horizontal: false,
          options: [
            {
              option: "",
              point: 0,
            },
          ],
        },
      ],
    },
  ]);

  const history = useNavigate();
  const initial = useContext(InitialContext);

  const handleAddSection = () => {
    let test = {
      form_category: "",
      category_weightage: 50,
      category_description: "",
      questions: [
        {
          question_text: "",
          question_description: "",
          type: "single_choice",
          q_type: "normal",
          is_required: true,
          is_horizontal: false,
          options: [
            {
              option: "",
              point: 0,
            },
          ],
        },
      ],
    };
    setSections([...sections, test]);
    setQuestionCount((prev) => prev + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    initial.setSnacky({
      loading: true,
    });
    const payload = {
      form_title: formTitle,
      form_description: formDescription,
      oversell_threshold: oversellThreshold,
      percetage_of_oversell_threshold: oversellPercentage,
      percentage_decrease_in_overall_score: percentageDecrease,
      sections,
    };
    await createForm(payload, initial, history);
  };

  const deleteQuestion = (section_index, question_index) => {
    let new_sections = sections;
    new_sections[section_index].questions.splice(question_index, 1);
    setSections([...new_sections]);
    setQuestionCount((prev) => prev - 1);
    console.log(new_sections);
  };

  const deleteOption = (section_index, question_index, option_index) => {
    let new_sections = sections;
    new_sections[section_index].questions[question_index].options.splice(
      option_index,
      1
    );
    setSections([...new_sections]);
    console.log(new_sections);
  };

  useEffect(() => {
    let temp = [];
    sections.map((section) => {
      temp.push(...section.questions);
    });
    const temp2 = temp.filter((q) => q.q_type === "oversell");
    setOversellQuestionCount(temp2.length);
  }, [sections]);

  return (
    <>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={(e) => handleSubmit(e)}
      >
        <Grid>
          <Grid container>
            <Grid item xs={8} className="mx-auto">
              <Paper className="px-4 pb-4 pt-3 headind__card" elevation={5}>
                <TextField
                  name="form_heading"
                  className="form__heading"
                  placeholder="Untitled form"
                  variant="standard"
                  value={formTitle}
                  onChange={(e) => setFormTitle(e.target.value)}
                  fullWidth
                />
                <TextField
                  name="form_description"
                  className="form__desc"
                  placeholder="Form description"
                  variant="standard"
                  value={formDescription}
                  onChange={(e) => setFormDescription(e.target.value)}
                  fullWidth
                />
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <TextField
                      variant="standard"
                      type="number"
                      name="oversell_threshold"
                      onChange={(e) => setOversellThreshold(e.target.value)}
                      placeholder="Oversell threshold counter"
                      fullWidth
                      InputProps={{
                        inputProps: {
                          max: 100,
                          min: 1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      variant="standard"
                      type="number"
                      name="oversell_percentage"
                      onChange={(e) => setOversellPercentage(e.target.value)}
                      placeholder="Percentage of oversell threshold"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          max: 100,
                          min: 1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      variant="standard"
                      type="number"
                      name="percentage_decrease"
                      onChange={(e) => setPercentageDecrease(e.target.value)}
                      placeholder="Percentage decrease"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          max: 100,
                          min: 1,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className="mx-auto mt-3">
              <Paper className="px-4 pb-4 pt-3" elevation={5}>
                <Stack direction="row" spacing={1}>
                  <Badge badgeContent={sections.length} color="primary">
                    <Chip
                      label={
                        <Typography display="block">
                          Total Categories
                        </Typography>
                      }
                    />
                  </Badge>
                  <Badge badgeContent={questionCount} color="primary">
                    <Chip
                      label={
                        <Typography display="block">Total Questions</Typography>
                      }
                    />
                  </Badge>
                  <Badge badgeContent={oversellQuestionCount} color="primary">
                    <Chip
                      label={
                        <Typography display="block">
                          Total Oversell Questions
                        </Typography>
                      }
                    />
                  </Badge>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
          {sections.map((x, index) => (
            <Grid key={index}>
              <Grid item xs={8} className="mx-auto mt-3">
                <Question
                  sectionIndex={index}
                  questions={x.questions}
                  setSections={setSections}
                  sections={sections}
                  deleteOption={deleteOption}
                  deleteQuestion={deleteQuestion}
                  setQuestionCount={setQuestionCount}
                />
              </Grid>
            </Grid>
          ))}
          <Grid container>
            <Grid
              sx={{ display: "flex", justifyContent: "flex-end" }}
              item
              xs={9}
              className="mx-auto mt-3"
            >
              <Button
                variant="contained"
                onClick={handleAddSection}
                startIcon={<ControlPointIcon />}
              >
                Category
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              sx={{ display: "flex", justifyContent: "flex-end" }}
              item
              xs={6}
              className="mx-auto my-3"
            >
              <Button type="submit" endIcon={<SendIcon />} variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Section;
