import React from "react";
import { Box, Grid } from "@mui/material";
import MatterLogo from "../../assets/Matter-logo-name.svg";
import "./index.css";
import background from "../../assets/congratulate.svg";
import { AnalyticEvents } from "../../Utils/Helpers/helpers";
import { CopylUrl } from "../../Utils/BaseUrl/BaseUrl";

function Congratulation() {
  const form_key = localStorage.getItem("form_key")
  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
  };

  AnalyticEvents("Form Submission", "Successfully submitted form");

  return (
    <>
      <div style={backgroundStyle}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "34%" }}
        >
          <Grid item xs={3}>
            <img src={MatterLogo} alt="Matter-logo-name" />
          </Grid>
        </Grid>
        <Grid className="text_padding">
          <Grid item xs={3}>
            <p className="txt_maturity">Congrats!</p>
            <p className="txt_sub">
              You’ve successfully submitted your application.
            </p>
          </Grid>
        </Grid>
        <Grid
          className="text_padding"
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "25%" }}
        >
          <Grid>
            <Box>
              <p className="txt_sub_v2">
                Review your results with our team{" "}
                <a style={{ color: "#1C5371", textDecoration: "underline" }} href={"https://calendly.com/ventureprograms/discovery-session"} target="_blank">
                  here.
                </a>
              </p>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Congratulation;
