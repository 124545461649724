import instance from "../../Utils/Axios/instance";

export const deleteForm = async (id, initial) => {
  try {
    const { status } = await instance.delete(`/form/${id}/`);
    if (status === 204) {
      initial.setSnacky({
        color: "success",
        message: "Form Deleted Successfully",
        open: true,
      });
      return true;
    }
  } catch (error) {
    console.log(error);
    initial.setSnacky({
      color: "error",
      message: "An Error Occurred while deleting the form!",
      open: true,
    });
    return false;
  }
};
