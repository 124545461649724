import axios from "axios";
import { BaseUrl } from "../../Utils/BaseUrl/BaseUrl";

export const loginUser = async (payload) => {
  try {
    const data = await axios.post(`${BaseUrl}/authenticate/`, payload);
    if (data) {
      return data;
    }
  } catch (error) {
    return error;
  }
};
