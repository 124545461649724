import {
  Register,
  RegisterForm,
  RegisterWelcome,
} from "./Screens/Register/index";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Congratulation } from "./Screens/Congratulation/index";
import { Response } from "./Screens/Response/index";
import { Landing } from "./Screens/LandingPage/index";
import ResponseLayout from "./Layout/ResponseLayout";
import ReviewLayout from "./Layout/ReviewLayout";
import { Review } from "./Screens/Review";
import Login from "./Screens/Login/login";
import { Form } from "./Screens/Form";
import { FormResponses } from "./Screens/FormResponses";
import { Result } from "./Screens/Result";
import { EditForm } from "./Screens/EditForm";
import NotFoundComponent from "./Components/NotFoundComponent/NotFoundComponent";
import NewDesign from "./Screens/NewDesign/NewDesign";
import "./App.css";
import ReactGA from "react-ga4";
import SecondPage from "./Screens/SecondPage/SecondPage";
function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Tuan",
  });
  const PrivateRoutes = () => {
    const location = useLocation();
    const authLogin = localStorage.getItem("user");
    return authLogin ? (
      <Outlet />
    ) : (
      <Navigate to="/login" replace state={{ from: location }} />
    );
  };
  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<Landing />} />
            <Route path="/create-form" element={<Form />} />
            <Route path="/responses/:key" element={<FormResponses />} />
            <Route path="/result/:form_response_key" element={<Result />} />
            <Route path="/edit/:key" element={<EditForm />} />
          </Route>
          <Route path="/register">
            <Route path=":key" element={<Register />} />
            <Route path="form/:key" element={<RegisterForm />} />
            <Route path="welcome/:key" element={<RegisterWelcome />} />
          </Route>
          <Route element={<ResponseLayout />}>
            <Route path="/response/:key" element={<Response />} />
          </Route>
          <Route element={<ReviewLayout />}>
            <Route path="/review" element={<Review />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/second-screen" element={<SecondPage />} />
          <Route path="/congrats" element={<Congratulation />} />
          <Route path="/scorecard/:key/:type" element={<NewDesign />} />
          <Route path="*" element={<NotFoundComponent />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
