import instance from "../../Utils/Axios/instance";

export const getOneForm = async (id) => {
  try {
    const { data } = await instance.get(`/form/${id}/`);
    if (data) {
      return data;
    }
  } catch (error) {
    error.error = true;
    return error;
  }
};
