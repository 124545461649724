import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { StartupScore } from "../../Apis/StartupScore";

const RadarChart = (props) => {
  useEffect(() => {
    const style = document.createElement("style");
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Series 1",
        data: [100, 100, 100, 100, 100, 100],
      },
    ],

    options: {
      chart: {
        height: 650,
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radar: {
          size: 300,
          polygons: {
            type: "circle",
            strokeColors: "rgba(255, 255, 255, 0.33)",
            fill: {
              colors: [
                "#00add0",
                "#34bdd9",
                "#65cee3",
                "#a3e1ee",
                "#e5f7fa",
                "#fff",
              ],
            },
          },
        },
      },
      title: {
        text: "",
      },
      colors: ["#FFF"],
      markers: {
        size: 6,
        colors: ["#00add0"],
        strokeColor: "#fff",
        strokeWidth: 2,
      },
      tooltip: {
        enabled: false,
        // y: {
        //     formatter: function (val) {
        //         return val;
        //     },
        // },
      },
      xaxis: {
        categories: [
          "Innovation",
          "Legal",
          "Market",
          "Resources",
          "Team",
          "Business model",
        ],
        labels: {
          style: {
            colors: "blue",
            fontFamily: "Nexa Bold",
            fontSize: "16px",
          },
        },
      },
      yaxis: {
        tickAmount: 6,
        categories: [],
        min: 0,
        max: 100,
        labels: {
          display: true,
          formatter: function (val, i) {
            if (i % 2 === 0) {
              return "";
            } else {
              return "";
            }
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 200,
            },
          },
        },
        // Add more breakpoints as needed
      ],
    },
  });

  useEffect(() => {
    (async () => {
      const data = await StartupScore(props.scorecard);
      const percentage_ratio = 100;
      const updatedSeries = [
        {
          ...chartData.series[0], // Keep other properties of the series intact
          data: [
            data[0].scaled_skewed_percentage.INNOVATION * percentage_ratio,
            data[0].scaled_skewed_percentage.LEGAL * percentage_ratio,
            data[0].scaled_skewed_percentage.MARKET * percentage_ratio,
            data[0].scaled_skewed_percentage.RESOURCES * percentage_ratio,
            data[0].scaled_skewed_percentage.TEAM * percentage_ratio,
            data[0].scaled_skewed_percentage.BUSINESS * percentage_ratio,
          ],
        },
      ];
      const mappedData = updatedSeries[0].data.map((value) =>
        value === 0 ? 10 : value
      );
      const mappedSeries = [
        {
          ...chartData.series[0], // Keep other properties of the series intact
          data: mappedData,
        },
      ];
      setChartData({
        ...chartData,
        series: mappedSeries,
      });
    })();
  }, []);

  return (
    //   <div id="chart" style={{ transform: 'rotate(30deg)' }}>
    //   <Chart options={chartData.options} series={chartData.series} type="radar" height={650} />
    // </div>

    <div style={{ position: "relative" }}>
      {/* <div id="chart" style={{ transform: 'rotate(30deg)' }}> */}
      <div id="chart" style={{ width: "100%" }}>
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="radar"
          height={650}
        />
      </div>
      <img
        src="https://maturity.matter.health/matter_logo.svg"
        alt="logo"
        className={`${props.classes}`}
      />
    </div>
  );
};

export default RadarChart;
