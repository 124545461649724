// const categoryColors = {
//   "Business Model": "#1C5371",
//   Innovation: "#B7E0EB",
//   Legal: "#FECF5D",
//   Market: "#2C2E37",
//   Resources: "#DB1F5A",
//   Team: "#00ADD0",
// };
//
// const categoryWidth = {
//   "Business Model": 0,
//   Innovation: 0,
//   Legal: 0,
//   Market: 0,
//   Resources: 0,
//   Team: 0,
// };
// const categoryLightColors = {
//   "Business Model": "rgba(28,83,113,0.32)",
//   Innovation: "rgba(183,224,235,0.32)",
//   Legal: "rgba(254,207,93,0.36)",
//   Market: "rgba(44,46,55,0.35)",
//   Resources: "rgba(219,31,90,0.34)",
//   Team: "#b7e0eb",
// };

const constantColors = [
  "#1C5371",
  "#B7E0EB",
  "#FECF5D",
  "#2C2E37",
  "#DB1F5A",
  "#00ADD0",
  "#1C5371",
  "#B7E0EB",
  "#FECF5D",
  "#2C2E37",
  "#DB1F5A",
  "#00ADD0",
];
const constantLightColors = [
  "#b8c7d1",
  "#e8f5f9",
  "#ffedc7",
  "#b5b6b9",
  "#f6b7c7",
  "#b7e0eb",
  "#b8c7d1",
  "#e8f5f9",
  "#ffedc7",
  "#b5b6b9",
  "#f6b7c7",
  "#b7e0eb",
];

export {
  // categoryColors,
  // categoryLightColors,
  // categoryWidth,
  constantColors,
  constantLightColors,
};
