import React from "react";
import Skeleton from "@mui/material/Skeleton";

function ResponsePlaceholders(props) {
  return (
    <div style={{ margin: "0 auto", width: "60rem" }}>
      {Array.from({ length: props.amount }).map((val, index) => (
        <div style={{ paddingTop: "10px", paddingBottom: "10px" }} key={index}>
          <Skeleton
            variant={props.variant}
            height={32}
            width={props.width}
            animation={props.animation}
          />
        </div>
      ))}
    </div>
  );
}

export default ResponsePlaceholders;
