import axios from "axios";
import { BaseUrl } from "../BaseUrl/BaseUrl";

const instance = axios.create({
  baseURL: BaseUrl,
});

if (localStorage.getItem("user")) {
  instance.interceptors.request.use((config) => {
    const accessToken = JSON.parse(localStorage.getItem("user")).data
      .access_token;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });
}

export default instance;
