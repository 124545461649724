import styled from "styled-components";

export const ProgressComponent = styled.div`
  :nth-child(1)::before {
    content: "";
    position: absolute;
    width: 20px;
    height: ${(props) => props?.height};
    background: #1c5371;
    margin-top: 24px;
    margin-left: -8px;
    border-radius: 5px;
  }
`;

export const LastProgress = styled.div`
  height: ${(props) => props?.height};
  margin-right: 10px;
  overflow: visible !important;

  &::after {
    transform: skew(25deg);
    transition: transform 0.5s ease-in-out;
    content: "";
    position: absolute;
    width: 45px;
    height: ${(props) => props?.height};
    border-radius: 5px;
    right: -8px;
    z-index: -1000;
    background: ${(props) => {
      if (props.stages) {
        if (
          props.stages.length - 1 === props.categoryIndex &&
          props.stages[props.stages.length - 1].questions.length - 1 ===
            props.questionIndex
        ) {
          return `${props.backgroundColor}`;
        } else {
          return `${props.backgroundColorLight}`;
        }
      } else {
        return props.backgroundColor;
      }
    }};
  }
`;
export const LastProgress2 = styled.div`
  height: ${(props) => props?.height};
  margin-right: 10px;
  overflow: visible !important;

  &::after {
    transform: skew(25deg);
    transition: transform 0.5s ease-in-out;
    content: "";
    position: absolute;
    width: 45px;
    height: ${(props) => props?.height};
    border-radius: 5px;
    right: -8px;
    z-index: -1000;
    background: ${(props) => {
      return props.backgroundColor;
    }}
  }
;;
}
`;
