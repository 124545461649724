import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import styles from "./NewDesign.module.css";
import { Box, Divider } from "@mui/material";
import { AnalyticEvents } from "../../Utils/Helpers/helpers";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: "20px solid #fff",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    sx={{ flexDirection: "row-reverse" }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: "10px",
  border: "1px solid #00ADD0",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#F2F9FB",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({ data, parentRequest }) {
  const bookmark = (data, resourceType) => {
    if (resourceType === "completed") {
      data.completed = true;
    }
    if (resourceType === "bookmarked") {
      data.bookmarked = true;
    }
    if (resourceType === "deleted") {
      data.bookmarked = true;
    }
    if (resourceType !== "visited")
      parentRequest(data, resourceType);
    AnalyticEvents(
      JSON.stringify({
        data: data
      }),
      "Roadmap",
      `Resource ${resourceType}`
    )
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const [expanded, setExpanded] = React.useState(["panel1"]);

  const handleChange = (panel) => (event, newExpanded) => {
    if (expanded.includes(panel)) {
      let index = expanded.indexOf(panel);
      expanded.splice(index, 1);
      setExpanded((old) => [...old]);
    } else {
      setExpanded((old) => [...old, panel]);
    }
  };

  const computeCapsuleIconText = (resource) => {

    let capIcon = "/assets/article.svg";
    let capText = "Article"
    if (resource.resource_type === "Video series") {
      capIcon = "/assets/video.svg"
      capText = "Video"
    } else if (resource.resource_type === "Mentor") {
      capIcon = "/assets/meeting.svg"
      capText = "Meeting"
    } else if (resource.resource_type === "Article") {
      capIcon = "/assets/article.svg"
      capText = "Article"
    } else if (resource.resource_type === "Vendor") {
      capIcon = "/assets/article.svg"
      capText = "Vendor"
    }
    else if (resource.resource_type === "Document" || resource.resource_type === "Document(s)") {
      capIcon = "/assets/article.svg"
      capText = "Document"
    }
    else if (resource.resource_type === "Partnerships/Opportunities") {
      capIcon = "/assets/article.svg"
      capText = "Partnerships/Opportunities"
    }
    return (
      <React.Fragment>
        <img style={{ height: "16px" }} src={`${capIcon}`} alt="" />
        <h6 className={`${styles.resource_capsule_text}`} style={{ marginTop: "5px", marginBottom: "0", fontSize: "14px" }}>{capText}</h6>
      </React.Fragment>
    )
  }
  return (
    <div>
      {data &&
        Object.entries(data).map(([goal, resources], index) => {
          const resourceArray = Array.isArray(resources) ? resources : [];

          return (
            
            <React.Fragment key={index}>
              <Accordion
                expanded={expanded.includes(`panel${index + 1}`)}
                onChange={handleChange(`panel${index + 1}`)}
              >
                <AccordionSummary
                  style={{ flexDirection: "row" }}
                  aria-controls={`panel${index + 1}d-content`}
                  id={`panel${index + 1}d-header`}
                >
                  <Typography
                    style={{ fontFamily: "Nexa Bold", fontSize: "18px" }}
                  >
                    {capitalizeFirstLetter(goal.replace(/_/g, " "))}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    {resourceArray.map((resource, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={`d-flex justify-content-between ${styles.goals_cards}`}
                        >
                          <div className="d-flex">
                            <div style={{ marginRight: "32px" }}>
                              <div>
                                {/* <img className={`${styles.resource_thumbnail_image}`}
                                  style={{ border: `${resource.color_scheme} solid 3px` }}
                                  src={"/assets/Image.svg"} alt="" /> */}
                                {/* TODO after completion uncomment below and delete above */}
                                <img className={`${styles.resource_thumbnail_image}`}
                                  style={{
                                    border: `${resource.color_scheme} solid 3px`,
                                    width: '100.8px',
                                    height: '76.8px'
                                  }}
                                  src={resource.thumbnail ? resource.thumbnail : "/assets/Image.svg"} alt="" />
                              </div>
                              <div className={`${styles.resource_capsule}`} style={{ backgroundColor: resource.color_scheme }}>
                                {computeCapsuleIconText(resource)}
                              </div>
                            </div>

                            <div>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <h6 className={`${styles.goal_title}`} style={{ marginRight: "10px" }}>
                                  {resource.name}
                                </h6>
                                <img
                                  src={resource.bookmarked ? "/assets/bookmark.svg" : "/assets/bookmark_grey.svg"}
                                  className={`${styles.custom_pointer} me-2 ${styles.custom_height}`}
                                  alt=""
                                  width="40px"
                                  onClick={() => {
                                    if (!resource.bookmarked) bookmark(resource, "bookmarked");
                                  }}
                                  onMouseEnter={(e) => (e.currentTarget.src = "/assets/bookmark.svg")}
                                  onMouseLeave={(e) =>
                                    (e.currentTarget.src = resource.bookmarked ? "/assets/bookmark.svg" : "/assets/bookmark_grey.svg")
                                  }
                                />
                              </div>
                              <p className={`${styles.goal_description}`}>
                                {resource.description}
                              </p>
                            </div>
                          </div>
                          <div className={`d-flex position-relative ${styles.buttons_align_center}`}>
                            <div style={{ position: 'relative' }}>
                              <img
                                src={resource.completed ? "/assets/blue_tick.svg" : "/assets/white_tick.svg"}
                                className={`${styles.custom_pointer} me-2`}
                                alt=""
                                width="40px"
                                onClick={() => bookmark(resource, "completed")}
                                onMouseEnter={(e) => {
                                  e.currentTarget.src = "/assets/blue_tick.svg";
                                  // Display the tick_hover.svg tooltip
                                  e.currentTarget.nextElementSibling.style.display = 'block';
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.src = resource.completed ? "/assets/blue_tick.svg" : "/assets/white_tick.svg";
                                  // Hide the tick_hover.svg tooltip
                                  e.currentTarget.nextElementSibling.style.display = 'none';
                                }}
                              />
                              <img
                                src="/assets/tick_hover.svg" // The path to your tooltip SVG
                                className={`${styles.custom_tooltip}`}
                                alt="Tooltip"
                                style={{
                                  display: 'none', // Initially hidden
                                  position: 'absolute',
                                  zIndex: '10',
                                  bottom: '100%', // Position it just above the button
                                  left: '50%', // Center it horizontally
                                  transform: 'translateX(-50%) translateY(170px)', // Adjust vertical position and center horizontally
                                  pointerEvents: 'none', // Ignore pointer events so it doesn't interfere with the button
                                }}
                              />
                            </div>

                            <div style={{ position: 'relative' }}>
                              <img
                                src="/assets/white_remove.svg"
                                className={`${styles.custom_pointer} me-2`}
                                alt=""
                                width="40px"
                                onClick={() => bookmark(resource, "deleted")}
                                onMouseEnter={(e) => {
                                  e.currentTarget.src = "/assets/red_remove.svg";
                                  // Display the remove_hover.svg tooltip
                                  e.currentTarget.nextElementSibling.style.display = 'block';
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.src = "/assets/white_remove.svg";
                                  // Hide the remove_hover.svg tooltip
                                  e.currentTarget.nextElementSibling.style.display = 'none';
                                }}
                              />
                              <img
                                src="/assets/remove_hover.svg" // The path to your tooltip SVG
                                className={`${styles.custom_tooltip}`}
                                alt="Tooltip"
                                style={{
                                  display: 'none', // Initially hidden
                                  position: 'absolute',
                                  zIndex: '10',
                                  bottom: '100%', // Position it just above the button
                                  left: '50%', // Center it horizontally
                                  transform: 'translateX(-50%) translateY(170px)', // Adjust vertical position and center horizontally
                                  pointerEvents: 'none', // Ignore pointer events so it doesn't interfere with the button
                                }}
                              />
                            </div>
                            <a
                              href={resource.url}
                              target="_blank"
                              className={`btn ${styles.modal_btn} ms-0`}
                              style={{
                                width: "190px",
                                display: 'inline-block',
                                lineHeight: '40px',
                                verticalAlign: 'middle',
                                marginTop: '-5px' // This centers the button vertically with the images
                              }}
                              onClick={() => bookmark(resource, "visited")}
                            >
                              {resource.caption}
                            </a>
                          </div>

                        </div>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          );
        })}
    </div>
  );
}
