import { createSlice } from "@reduxjs/toolkit";
import {
  constantColors,
  constantLightColors,
} from "../../Utils/Constants/constants";

const initialState = {
  categoryColors: {
    "business model": "#1C5371",
    innovation: "#B7E0EB",
    legal: "#FECF5D",
    market: "#2C2E37",
    resources: "#DB1F5A",
    team: "#00ADD0",
  },

  categoryWidth: {
    "business model": 0,
    innovation: 0,
    legal: 0,
    market: 0,
    resources: 0,
    team: 0,
  },
  categoryLightColors: {
    "business model": "#b8c7d1",
    innovation: "#e8f5f9",
    legal: "#ffedc7",
    market: "#b5b6b9",
    resources: "#f6b7c7",
    team: "#b7e0eb",
  },
  percentage: 0,
  previous: "business model",
};

export const constantSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    setCategoryConstants: (state, action) => {
      let tempObjectWidth = {};
      let tempObjectLightColor = {};
      let tempObjectColor = {};
      action.payload.categories.forEach((cat, index) => {
        tempObjectWidth[`${cat.kind.toLowerCase()}`] = 0;
        tempObjectLightColor[`${cat.kind.toLowerCase()}`] =
          constantLightColors[index];
        tempObjectColor[`${cat.kind.toLowerCase()}`] = constantColors[index];
      });
      state.categoryColors = tempObjectColor;
      state.categoryLightColors = tempObjectLightColor;
      state.categoryWidth = tempObjectWidth;
      state.percentage = 100 / action.payload.categories[0].questions.length;
      state.categoryWidth[action.payload.categories[0].kind.toLowerCase()] =
        100 / action.payload.categories[0].questions.length;
      state.previous = action.payload.categories[0].kind.toLowerCase();
    },
    setCategoryWidth: (state, action) => {
      state.categoryWidth = action.payload;
    },
    setPercentAge: (state, action) => {
      state.percentage = action.payload;
    },
    setPrevious: (state, action) => {
      state.previous = action.payload.toLowerCase();
    },
  },
});

export const {
  setCategoryConstants,
  setCategoryWidth,
  setPercentAge,
  setPrevious,
} = constantSlice.actions;
export default constantSlice.reducer;
