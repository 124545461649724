import { Paper, Skeleton } from "@mui/material";
import React from "react";

const SkeletonLoading = (props) => {
  return (
    <>
      <Paper
        className={props.class}
        sx={{ height: props.height }}
        elevation={5}
      >
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ fontSize: "1rem", width: "45vw" }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ fontSize: "1rem", width: "20vw" }}
        />
      </Paper>
      <Paper
        className={props.class}
        sx={{ height: props.height }}
        elevation={5}
      >
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ fontSize: "1rem", width: "45vw" }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ fontSize: "1rem", width: "20vw" }}
        />
      </Paper>
      <Paper
        className={props.class}
        sx={{ height: props.height }}
        elevation={5}
      >
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ fontSize: "1rem", width: "45vw" }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ fontSize: "1rem", width: "20vw" }}
        />
      </Paper>
    </>
  );
};

export default SkeletonLoading;
