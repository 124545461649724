import instance from "../../Utils/Axios/instance";

export const getResponsesOfForm = async (key) => {
  try {
    const { data } = await instance.get(`/form_response?form_key=${key}`);
    if (data) {
      return data;
    }
  } catch (error) {
    error.error = true;
    return error;
  }
};
