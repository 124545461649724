import React, { useEffect, useState } from "react";
import instance from "../../Utils/Axios/instance";
import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SkeletonLoading from "../../Components/Skeleton";
import CircularProgress from "../../Components/CircularProgress/CircularProgress";

const Result = () => {
  const { form_response_key } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetch = async () => {
    try {
      const { data } = await instance.get(`/total/${form_response_key}`);
      if (data) {
        setData(data);
        setLoading(false);
        document.getElementById("myText").innerHTML = JSON.stringify(
          data,
          null,
          4
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      {/* <pre id="myText" ></pre> */}
      <Grid container>
        {loading ? (
          <>
            <Grid md={8} className="mx-auto mt-3">
              <SkeletonLoading class="px-4 pb-4  pt-3 my-2 " height="30vh" />
            </Grid>
          </>
        ) : (
          data.map((result, index) => {
            // console.log(
            //     "result?.lowest_three_questions",
            //     result?.lowest_three_questions
            // )
            return (
              <React.Fragment key={index}>
                <Grid md={8} className="mx-auto mt-3 ">
                  <Paper className="px-4 pb-4 pt-3  " elevation={5}>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <Typography variant="h5" component="h2">
                          Total Score:{" "}
                          {Math.floor(result.original_total_score * 100) / 100}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="h5" component="h2">
                          Total Skewed Score:{" "}
                          {Math.floor(
                            result.original_total_skewed_score * 100
                          ) / 100}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="h5" component="h2">
                          Total Skewed Percentage:{" "}
                          {Math.floor(result.total_skewed_percentage * 100) /
                            100}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="h5" component="h2">
                          Oversell Reduced Total Score:{" "}
                          {Math.floor(
                            result.oversell_reduced_total_score * 100
                          ) / 100}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="h5" component="h2">
                          Total Reduced Skewed Score:{" "}
                          {Math.floor(
                            result.oversell_reduced_total_skewed_score * 100
                          ) / 100}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid md={8} className="mx-auto mt-3 ">
                  <Paper className="px-4 pb-4 pt-3  " elevation={5}>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography variant="h4" component="h2">
                          Lowest Three Questions
                        </Typography>
                      </Grid>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              variant="head"
                              style={{ fontWeight: "bold" }}
                            >
                              Questions{" "}
                            </TableCell>
                            {/*<TableCell align="right">Choices</TableCell>*/}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {result?.lowest_three_questions.map(
                            (value, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <TableRow key={value}>
                                    <TableCell component="th" scope="row">
                                      {value?.question?.text}

                                      <Table
                                        sx={{ minWidth: 600 }}
                                        size="small"
                                        aria-label="a dense table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              variant="head"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Choices{" "}
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {value?.question?.choices.map(
                                            (ele, index) => (
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                key={index}
                                                style={{ color: "#4f4e4e" }}
                                              >
                                                {ele}
                                              </TableCell>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid md={8} className="mx-auto mt-3 ">
                  <Paper className="px-4 pb-4 pt-3  " elevation={5}>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography variant="h4" component="h2">
                          Skewed By Weight
                        </Typography>
                        {/* <Typography variant="h5" component="h2">
                      Categories :
                                            </Typography> */}
                      </Grid>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Categories </TableCell>
                            <TableCell align="right">Points</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object?.keys(result?.skewed_by_desired_weights).map(
                            (value, skewed_id) => {
                              return (
                                <React.Fragment key={skewed_id}>
                                  <TableRow key={value}>
                                    <TableCell component="th" scope="row">
                                      {value}
                                    </TableCell>
                                    <TableCell align="right">
                                      {Math.floor(
                                        result.skewed_by_desired_weights[
                                          value
                                        ] * 100
                                      ) / 100}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                    {/* <Grid item md={6}>
                                        <Typography variant="h6" component="h2">
                                            {value + ' : ' + Math.floor(result.skewed_by_desired_weights[value] * 100) / 100}
                                        </Typography>
                                    </Grid> */}
                    {/* </Grid> */}
                  </Paper>
                </Grid>
                <Grid md={8} className="mx-auto mt-3 ">
                  <Paper className="px-4 pb-4 pt-3  " elevation={5}>
                    <div>
                      <CircularProgress
                        data={result?.skewed_percentage}
                        labels={Object?.keys(result?.skewed_percentage)}
                        series={Object?.values(result?.skewed_percentage).map(
                          (val) => Math.round(val * 100)
                        )}
                      />
                    </div>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography variant="h4" component="h2">
                          Skewed Percentage
                        </Typography>
                        {/* <Typography variant="h5" component="h2">
                                                Categories :
                                            </Typography> */}
                      </Grid>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Categories</TableCell>
                            <TableCell align="right">Points</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object?.keys(result?.skewed_percentage).map(
                            (value, skPercentId) => {
                              return (
                                <React.Fragment key={skPercentId}>
                                  <TableRow key={value}>
                                    <TableCell component="th" scope="row">
                                      {value}
                                    </TableCell>
                                    <TableCell align="right">
                                      {Math.floor(
                                        result.skewed_percentage[value] * 100
                                      ) / 100}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid md={8} className="mx-auto mt-3 ">
                  <Paper className="px-4 pb-4 pt-3  " elevation={5}>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography variant="h4" component="h2">
                          Category Score
                        </Typography>
                        {/* <Typography variant="h5" component="h2">
                                                Categories :
                                              </Typography> */}
                      </Grid>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Categories</TableCell>
                            <TableCell align="right">Points</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object?.keys(result?.category_score).map(
                            (value, cid) => {
                              return (
                                <React.Fragment key={cid}>
                                  <TableRow key={value}>
                                    <TableCell component="th" scope="row">
                                      {value}
                                    </TableCell>
                                    <TableCell align="right">
                                      {Math.floor(
                                        result.category_score[value] * 100
                                      ) / 100}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid md={8} className="mx-auto mt-3 ">
                  <Paper className="px-4 pb-4 pt-3  mb-2 " elevation={5}>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography variant="h4" component="h2">
                          Category Statistics
                        </Typography>
                        {/* <Typography variant="h5" component="h2">
                                                Categories :
                                            </Typography> */}
                      </Grid>
                      {Object?.keys(result?.category_statistics).map(
                        (value) => {
                          return (
                            <>
                              <Grid item md={12}>
                                <Typography variant="h5" component="h2">
                                  {value + ":"}
                                </Typography>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  size="small"
                                  aria-label="a dense table"
                                >
                                  <TableBody>
                                    {Object?.keys(
                                      result?.category_statistics[value]
                                    ).map((val) => {
                                      return (
                                        <>
                                          {val !== "questions_threshold" && (
                                            <TableRow key={val}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {val}
                                              </TableCell>
                                              <TableCell align="right">
                                                {Math.floor(
                                                  result.category_statistics[
                                                    value
                                                  ][val] * 100
                                                ) / 100}
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Grid>
                            </>
                          );
                        }
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              </React.Fragment>
            );
          })
        )}
      </Grid>
    </>
  );
};

export default Result;
