import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Skeleton,
  Paper,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOneForm } from "../../Apis/Form/getOneForm";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import EditFormCategory from "./EditFormCategory";
import SendIcon from "@mui/icons-material/Send";
import { EditFormApi, DuplicateFormApi } from "../../Apis/Form/EditForm";
import { InitialContext } from "../../Utils/Context/InitailContext";
import SkeletonLoading from "../../Components/Skeleton";
import { setResponse } from "../../Store/Reducers/ResponseSlice";
import { setCategoryConstants } from "../../Store/Reducers/ConstantSlice";

const EditForm = () => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [saveOption, setSaveOption] = useState(false);

  const history = useNavigate();
  const initial = useContext(InitialContext);
  const { key } = useParams();

  const getForm = async () => {
    const response = await getOneForm(key);

    if (response !== 404) {
      setForm(response);
      setLoading(false);
    } else {
      initial.setSnacky({
        color: "error",
        message: `${response.message}!`,
        open: true,
        loading: false,
      });
      setLoading(false);
    }
  };

  const handleAddCategory = () => {
    let test = {
      kind: "",
      weightage_in_percentage: 50,
      description: "",
      questions: [
        {
          text: "",
          description: "",
          question_type: "SC",
          question_class: "N",
          required: true,
          is_horizontal: false,
          choices: [
            {
              option: "",
              points: 0,
            },
          ],
        },
      ],
    };
    let newSections = [...form.categories];
    newSections.push(test);
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  const handleForm = (e) => {
    let formData = form;
    formData[e.target.name] = e.target.value;
    setForm({ ...formData });
  };

  const handleSubmit = async () => {
    console.log(form);
    initial.setSnacky({
      loading: true,
    });
    const data = await DuplicateFormApi(key, form);
    if (data) {
      initial.setSnacky({
        color: "success",
        message: "Form Updated copy of form created successfully successfully.",
        open: true,
        loading: false,
      });
      history("/");
    } else {
      initial.setSnacky({
        color: "error",
        message: `An error occurred while creating duplicate Form!`,
        open: true,
        loading: false,
      });
    }
  };

  const handleSubmitCurrentForm = async () => {
    // console.log(form);
    initial.setSnacky({
      loading: true,
    });
    const data = await EditFormApi(key, form);
    if (data) {
      initial.setSnacky({
        color: "success",
        message: "Form Updated successfully.",
        open: true,
        loading: false,
      });
      history("/");
    } else {
      initial.setSnacky({
        color: "error",
        message: `An error occurred while updating the Form!`,
        open: true,
        loading: false,
      });
    }
  };

  const deleteCategory = (section_index) => {
    let newSections = [...form.categories];
    newSections.splice(section_index, 1);
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  const deleteQuestion = (section_index, question_index) => {
    let newSections = [...form.categories];
    newSections[section_index].questions.splice(question_index, 1);
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  const deleteOption = (section_index, question_index, option_index) => {
    let newSections = [...form.categories];
    newSections[section_index].questions[question_index].choices.splice(
      option_index,
      1
    );
    let formData = form;
    formData.categories = newSections;
    setForm({ ...formData });
  };

  useEffect(() => {
    getForm();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {saveOption ? (
          <>
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to modify this form?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                It will cause important data loss. Please prefer "Save as" in
                that situation.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSaveOption(false)}>Back</Button>
              <Button onClick={handleSubmitCurrentForm}>Confirm</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              {"Select Options"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Select the option you want to continue with
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSaveOption(true)}>Save</Button>
              {/* calling api to make dublicate form */}
              <Button onClick={handleSubmit}>Save As</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Grid>
        {loading ? (
          <>
            <Grid container>
              <Grid item xs={8} className="mx-auto">
                <Paper className="px-4 pb-4 pt-3 headind__card" elevation={5}>
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <Skeleton variant="text" animation="wave" />
                    </Grid>
                    <Grid item md={4}>
                      <Skeleton variant="text" animation="wave" />
                    </Grid>
                    <Grid item md={4}>
                      <Skeleton variant="text" animation="wave" />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} className="mx-auto mt-3 ">
                <SkeletonLoading
                  class="px-4 pb-4 pt-3 my-2 headind__card"
                  height="25vh"
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container>
              <Grid item xs={8} className="mx-auto">
                <Paper className="px-4 pb-4 pt-3 headind__card" elevation={5}>
                  <TextField
                    name="name"
                    className="form__heading"
                    placeholder="Untitled form"
                    variant="standard"
                    value={form.name}
                    onChange={(e) => handleForm(e)}
                    fullWidth
                  />
                  <TextField
                    name="description"
                    className="form__desc"
                    placeholder="Form description"
                    variant="standard"
                    value={form.description}
                    onChange={(e) => handleForm(e)}
                    fullWidth
                  />
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <TextField
                        variant="standard"
                        type="number"
                        name="oversell_threshold"
                        value={form.oversell_threshold}
                        onChange={(e) => handleForm(e)}
                        placeholder="Oversell threshold"
                        fullWidth
                        InputProps={{
                          inputProps: {
                            max: 100,
                            min: 1,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        variant="standard"
                        type="number"
                        name="percetage_of_oversell_threshold"
                        value={form.percetage_of_oversell_threshold}
                        onChange={(e) => handleForm(e)}
                        placeholder="Percentage of oversell threshold"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputProps: {
                            max: 100,
                            min: 1,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        variant="standard"
                        type="number"
                        name="percentage_decrease_in_overall_score"
                        value={form.percentage_decrease_in_overall_score}
                        onChange={(e) => handleForm(e)}
                        placeholder="Percentage decrease"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputProps: {
                            max: 100,
                            min: 1,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            {form?.categories?.map((category, index) => (
              <Grid key={index}>
                <Grid item xs={8} className="mx-auto mt-3">
                  <EditFormCategory
                    deleteQuestion={deleteQuestion}
                    deleteOption={deleteOption}
                    categoryIndex={index}
                    category={category}
                    setForm={setForm}
                    form={form}
                    categories={form.categories}
                    deleteCategory={deleteCategory}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid container>
              <Grid
                sx={{ display: "flex", justifyContent: "flex-end" }}
                item
                xs={9}
                className="mx-auto mt-3"
              >
                <Button
                  variant="contained"
                  onClick={handleAddCategory}
                  startIcon={<ControlPointIcon />}
                >
                  Category
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                sx={{ display: "flex", justifyContent: "flex-end" }}
                item
                xs={6}
                className="mx-auto my-3"
              >
                <Button
                  type="submit"
                  endIcon={<SendIcon />}
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default EditForm;
