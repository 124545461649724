import ReactGA from "react-ga4";
function toPascalCase(sentence) {
  const words = sentence.toLowerCase().split(" ");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
}
function capitalizeFirstLetter(str) {
  if (str.length === 0) {
    return str; // Return empty string if input is empty
  }

  const firstChar = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1).toLowerCase();

  return firstChar + restOfString;
}

const AnalyticEvents = (category, action, label = "test") => {
  // console.log(category,action,label)
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
export { AnalyticEvents, toPascalCase, capitalizeFirstLetter };
