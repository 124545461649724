import React, { useState } from "react";
import "./index.css";
import { Box } from "@mui/material";
import { AnalyticEvents } from "../Utils/Helpers/helpers";

const limit_char = 200;
const OpenText = (props) => {
  let description = props.description || "My response is...";
  const [isLimit, setIsLimit] = useState(false);
  const handleChange = (event) => {
    if (event.target.value.length <= limit_char) {
      setChars(limit_char - event.target.value.length);
      if (props.questionIndex) {
        props.handleTextValue(
          event,
          props.questionIndex,
          props.categoryIndex,
          chars
        );
      } else {
        props.handleTextValue(event, chars);
      }
      if (event.target.value.length === limit_char) {
        if (!isLimit) {
            AnalyticEvents(
              JSON.stringify({
                props: props,
                reachedAt: new Date(),
              }),
              "Assessment",
              "Open text limit reach"
            );
            setIsLimit(true);    
        }
        else
            setIsLimit(false);
        }
    }
  };
  const [chars, setChars] = useState(0);

  return (
    <Box
      className={
        props.isReview
          ? "text_box_width_review width-wrapper"
          : "width-wrapper text_box_width_response"
      }
    >
      <textarea
        className="text_area_style width-wrapper"
        placeholder={description}
        rows="4"
        cols="40"
        value={props.value.answer_text ? props.value.answer_text : ""}
        onChange={handleChange}
        maxLength={limit_char}
      ></textarea>
      <div className="open-text-warning">
        (Remaining characters: {props.value.character_limit})
      </div>
    </Box>
  );
};

export default OpenText;
