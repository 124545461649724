import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import React, { createContext, useState } from "react";

export const InitialContext = createContext({
  snacky: {},
  setSnacky: (snack) => {},
});

export const IContext = ({ children }) => {
  const [snacky, setSnacky] = useState({
    color: "success",
    message: "Updated Successfully",
    open: false,
    loading: false,
  });

  const details = {
    snacky,
    setSnacky,
  };

  return (
    <InitialContext.Provider value={details}>
      {children}
      <Snackbar
        open={snacky.open}
        autoHideDuration={6000}
        onClose={() => setSnacky({ open: false })}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => setSnacky({ open: false })}
          severity={snacky.color}
          sx={{ width: "100%" }}
        >
          {snacky.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={snacky.loading}
      >
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress color="primary" disableShrink />
        </Box>
      </Backdrop>
    </InitialContext.Provider>
  );
};
