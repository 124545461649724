import instance from "../../Utils/Axios/instance";

export const getAllForm = async () => {
  try {
    const { data } = await instance.get("/form/");
    if (data) {
      return data;
    }
  } catch (error) {
    return error;
  }
};
