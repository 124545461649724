import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
  Checkbox,
  IconButton,
  Button,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import "./index.css";

function Fields({
  question,
  addOption,
  handleChange,
  formIndex,
  handleQuestion,
  handleType,
  handleQuestionType,
  handleOptionValue,
  categoryIndex,
  deleteQuestion,
  deleteOption,
}) {
  return (
    <Paper className="px-4 pb-4 pt-3" elevation={5}>
      <Grid container spacing={5}>
        <Grid item xs={7}>
          <TextField
            fullWidth
            variant="standard"
            name="text"
            className="question__field"
            value={question.text}
            placeholder="Question"
            onChange={(e) => handleQuestion(e, formIndex)}
          />
          <TextField
            fullWidth
            variant="standard"
            name="description"
            className="question__field"
            value={question.description}
            placeholder="Description"
            onChange={(e) => handleQuestion(e, formIndex)}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth className="mb-2">
            <InputLabel id="demo-simple-select-label"> Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={question.question_type}
              defaultValue="single_choice"
              label="Type"
              name="question_type"
              onChange={(e) => handleType(e, formIndex)}
            >
              <MenuItem value="SC">
                <RadioButtonCheckedIcon /> Single Choice
              </MenuItem>
              <MenuItem value="CB">
                <CheckBoxIcon /> Checkboxes
              </MenuItem>
              <MenuItem value="OT">
                <FormatColorTextIcon /> Open text
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {" "}
              Question Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={question.question_class}
              defaultValue="normal"
              name="question_class"
              label="Question Type"
              onChange={(e) => handleQuestionType(e, formIndex)}
            >
              <MenuItem value="N"> Normal</MenuItem>
              <MenuItem value="O"> Oversell</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth>
            {question.question_type === "SC" && question.is_horizontal ? (
              <>
                <Grid className="d-flex justify-content-center">
                  {question &&
                    question.choices.map((choice, choice_index) => {
                      return (
                        <React.Fragment key={choice_index}>
                          <FormControlLabel
                            label={choice.option}
                            value={choice.option}
                            control={<Radio />}
                            disabled
                          />
                        </React.Fragment>
                      );
                    })}
                </Grid>
              </>
            ) : (
              question &&
              question.choices &&
              question.choices.map((option, index) => {
                return (
                  <>
                    <Grid container spacing={2} key={index + "newoption"}>
                      <Grid item xs={1}>
                        <FormControlLabel
                          value=""
                          control={
                            question.question_type === "SC" ? (
                              <Radio />
                            ) : (
                              <Checkbox />
                            )
                          }
                          disabled
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          variant="standard"
                          name="option"
                          value={option.option}
                          onChange={(e) =>
                            handleOptionValue(e, formIndex, index)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="standard"
                          type="number"
                          name="points"
                          value={option.points}
                          placeholder="Enter point"
                          onChange={(e) =>
                            handleOptionValue(e, formIndex, index)
                          }
                          fullWidth
                          InputProps={{
                            inputProps: {
                              max: 300,
                              min: -300,
                              step: "0.01",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            deleteOption(categoryIndex, formIndex, index)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                );
              })
            )}

            {question.question_type !== "OT" && (
              <Grid container>
                <Grid item xs={3}>
                  <Button onClick={() =>addOption(formIndex)}>Add Option</Button>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={question.required}
                      onChange={() => handleChange(formIndex, "required")}
                      name="checkbox"
                    />
                  }
                  label="Required"
                />
              </Grid>
              {question.question_type === "SC" ? (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={question.is_horizontal}
                        onChange={() =>
                          handleChange(formIndex, "is_horizontal")
                        }
                        name="checkbox"
                      />
                    }
                    label="Horizontal Alignment"
                  />
                </Grid>
              ) : null}
            </Grid>
          </FormControl>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          <IconButton
            aria-label="delete"
            onClick={() => deleteQuestion(categoryIndex, formIndex)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Fields;
