import React, { useState } from "react";
import classes from "./RadioButton.module.css";
import { Container } from "@mui/material";

const RadioInput = (props) => {
  const [checkedParagraphId, setCheckedParagraphId] = useState(null);
  const handleRadioChange = (event, option) => {
    let paragraphId = option.id;
    if (checkedParagraphId === paragraphId) {
      setCheckedParagraphId(null);
    } else {
      setCheckedParagraphId(paragraphId);
    }
    props.onChange(paragraphId, props.categoryIndex, props.questionIndex);
  };

  return (
    <div
      className={`${!props.isReview && classes.scrollable_div} ${
        props.isReview
          ? classes.multiple_choice_container_review
          : classes.multiple_choice_container
      }`}
    >
      {props.options.map((option) => (
        <div key={option.id} className={classes.radio_container}>
          <input
            type="radio"
            id={option.id}
            name={props.label}
            value={option.points}
            checked={props.answer ? props.answer === option.id : ""}
            onChange={(e) => handleRadioChange(e, option)}
          />
          <label
            htmlFor={option.id}
            className={
              checkedParagraphId === option.id
                ? classes.radio_label_checked
                : classes.radio_label
            }
          >
            {option.option}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioInput;
