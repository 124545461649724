import React from "react";
import { Box, Grid } from "@mui/material";
import MatterLogo from "../../assets/Matter-logo-name-small.svg";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./Register.module.css";
import background from "../../assets/background_first.png";
import { useSelector } from "react-redux";
import { AnalyticEvents } from "../../Utils/Helpers/helpers";

function Register() {
  const history = useNavigate();
  let { key } = useParams();
  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
  };

  AnalyticEvents(
    "Registration",
    "Registration Completed SuccessFully",
    "The registration is completed!! "
  );

  const { userData } = useSelector((state) => state?.userData);
  return (
    <>
      <div style={backgroundStyle}>
        <Grid
          className={classes.landing_logo}
          container
          style={{ width: "80%" }}
        >
          <img
            className="matter-logo-form"
            src={MatterLogo}
            alt="matter-logo"
          />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.second_div}
        >
          <Grid item xs={8}>
            <p className={classes.text_register_welcome}>
              Welcome, {userData.first_name}!
            </p>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="row">
              <Grid item xs>
                <div className={classes.text_margin}>
                  <p className={classes.text_register_welcome_para}>
                    On the next page, there will be a multi-section
                    questionnaire.
                  </p>
                  <p
                    className={`${classes.text_register_welcome_para} ${classes.text_margin_two}`}
                  >
                    Please answer them to the best of your ability so <br />
                    we can get to know you better, and serve you better!{" "}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <div>
              <img
                style={{ marginBottom: "2rem" }}
                src="./assets/ProgressBar.png"
                alt="matter-logo"
              />
              <p
                className={`${classes.text_register_welcome_para} ${classes.text_margin_three}`}
              >
                This progress bar will tell you how far along you are in the
                application.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid
          className={classes.text_padding_rw}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          // style={{ minHeight: "20%" }}
        >
          <Grid>
            <Box>
              <button className={classes.questionnare}>
                <span onClick={() => history(`/response/${key}`)}>
                  Questionnaire
                </span>
                <img
                  src="./assets/arrow_right.png"
                  alt="arrow"
                  className={classes.right_arrow}
                />
              </button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Register;
