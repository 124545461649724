import React from "react";
import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import MatterLogo from "../../assets/Matter-logo-name-small.svg";
import background from "../../assets/background_first.png";
import classes from "./NotFoundComponent.module.css";
function NotFoundComponent(props) {
  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
  };
  return (
    <div style={backgroundStyle}>
      <Grid
        className="landing_logo"
        container
        style={{ minHeight: "20%", width: "80%" }}
      >
        <img src={MatterLogo} alt="matter-logo" />
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.flex_center}>
          <Box
            className={classes.flex_center}
            sx={{ flexDirection: "column", gap: "7.6rem" }}
          >
            <p className={classes.text_register_form}>Page Not Found!</p>
            <Box
              className={classes.flex_center}
              sx={{ flexDirection: "column", width: "40vw", gap: "4.67rem" }}
            ></Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        className="text_padding"
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "23.5%" }}
      >
        <Grid>
          <Box>
            <Link to="/">
              <button className={classes.nxt_btn}>Back To Main</button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default NotFoundComponent;
