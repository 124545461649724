import React from "react";
import MatterLogo from "../assets/Matter-logo-name-small.svg";
import { Outlet } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import classes from "./Layout.module.css";

const ReviewLayout = () => {
  return (
    <>
      <Grid container alignItems="center" className={classes.container_margins}>
        <Grid item xs={2} sm={3} md={2.5} textAlign="right">
          <img src={MatterLogo} alt="matter-logo" />
        </Grid>
        <Grid item xs={8} sm={8} md={8} textAlign="center"></Grid>
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontFamily: "Nexa Bold" }}
          >
            Review
          </Typography>
        </Grid>
      </Grid>
      <Outlet />
    </>
  );
};

export default ReviewLayout;
