import instance from "../../Utils/Axios/instance";

export const createForm = async (payload, initial, history) => {
  try {
    const { data } = await instance.post("/formdata/", payload);
    if (data) {
      initial.setSnacky({
        color: "success",
        message: "Form Created",
        open: true,
        loading: false,
      });
      history("/");
    }
  } catch (error) {
    console.log(error);
    initial.setSnacky({
      color: "error",
      message: "An Error Occurred while creating the form!",
      open: true,
      loading: false,
    });
  }
};
