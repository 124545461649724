import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
  Checkbox,
  IconButton,
  Button,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import "./index.css";

function Fields({
  x,
  addOption,
  handleChange,
  formIndex,
  handleQuestion,
  handleType,
  handleQuestionType,
  handleOptionValue,
  sectionIndex,
  deleteQuestion,
  deleteOption,
}) {
  return (
    <Paper className="px-4 pb-4 pt-3" elevation={5}>
      <Grid container spacing={5}>
        <Grid item xs={7}>
          <TextField
            fullWidth
            variant="standard"
            name="question_text"
            className="question__field"
            value={x.question_text}
            placeholder="Question"
            onChange={(e) => handleQuestion(e, formIndex)}
          />
          <TextField
            fullWidth
            variant="standard"
            name="question_description"
            className="question__field"
            value={x.question_description}
            placeholder="Description"
            onChange={(e) => handleQuestion(e, formIndex)}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth className="mb-2">
            <InputLabel id="demo-simple-select-label"> Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={x.type}
              defaultValue="single_choice"
              label="Type"
              name="type"
              onChange={(e) => handleType(e, formIndex)}
            >
              <MenuItem value="single_choice">
                <RadioButtonCheckedIcon /> Single Choice
              </MenuItem>
              <MenuItem value="checkbox">
                <CheckBoxIcon /> Checkboxes
              </MenuItem>
              <MenuItem value="text">
                <FormatColorTextIcon /> Open text
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {" "}
              Question Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={x.q_type}
              defaultValue="normal"
              name="q_type"
              label="Question Type"
              onChange={(e) => handleQuestionType(e, formIndex)}
            >
              <MenuItem value="normal"> Normal</MenuItem>
              <MenuItem value="oversell"> Oversell</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth>
            {x.type === "single_choice" ? (
              x.is_horizontal === true ? (
                <>
                  <Grid className="d-flex justify-content-center">
                    {x.options.map((choice, choice_index) => {
                      return (
                        <React.Fragment>
                          <FormControlLabel
                            label={choice.option}
                            value={choice.option}
                            control={<Radio />}
                            disabled
                          />
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </>
              ) : (
                <>
                  {x &&
                    x.options &&
                    x.options.map((y, index) => (
                      <Grid container spacing={2} key={index + "newoption"}>
                        <Grid item xs={1}>
                          <FormControlLabel
                            value=""
                            control={
                              x.type === "single_choice" ? (
                                <Radio />
                              ) : (
                                <Checkbox />
                              )
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            variant="standard"
                            name="option"
                            value={y.option}
                            onChange={(e) =>
                              handleOptionValue(e, formIndex, index)
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            variant="standard"
                            type="number"
                            name="point"
                            value={y.point}
                            placeholder="Enter point"
                            onChange={(e) =>
                              handleOptionValue(e, formIndex, index)
                            }
                            fullWidth
                            InputProps={{
                              inputProps: {
                                max: 300,
                                min: -300,
                                //  step: "0.01"
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              deleteOption(sectionIndex, formIndex, index)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                </>
              )
            ) : (
              <>
                {x &&
                  x.options &&
                  x.options.map((y, index) => (
                    <Grid container spacing={2} key={index + "newoption"}>
                      <Grid item xs={1}>
                        <FormControlLabel
                          value=""
                          control={
                            x.type === "single_choice" ? (
                              <Radio />
                            ) : (
                              <Checkbox />
                            )
                          }
                          disabled
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          variant="standard"
                          name="option"
                          value={y.option}
                          onChange={(e) =>
                            handleOptionValue(e, formIndex, index)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="standard"
                          type="number"
                          name="point"
                          value={y.point}
                          placeholder="Enter point"
                          onChange={(e) =>
                            handleOptionValue(e, formIndex, index)
                          }
                          fullWidth
                          InputProps={{
                            inputProps: {
                              max: 300,
                              min: -300,
                              //  step: "0.01"
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            deleteOption(sectionIndex, formIndex, index)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
              </>
            )}

            {x.type !== "text" && (
              <Grid container>
                <Grid item xs={3}>
                  <Button onClick={() =>addOption(formIndex)}>Add Option</Button>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={x.is_required}
                      onChange={() => handleChange(formIndex, "is_required")}
                      name="checkbox"
                    />
                  }
                  label="Required"
                />
              </Grid>
              {x.type === "single_choice" ? (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={x.is_horizontal}
                        onChange={() =>
                          handleChange(formIndex, "is_horizontal")
                        }
                        name="checkbox"
                      />
                    }
                    label="Horizontal Alignment"
                  />
                </Grid>
              ) : null}
            </Grid>
          </FormControl>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          <IconButton
            aria-label="delete"
            onClick={() => deleteQuestion(sectionIndex, formIndex)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Fields;
