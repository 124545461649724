import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import classes from "./Login.module.css";
import { loginUser } from "../../Apis/Login/login";
import MatterLogo from "../../assets/Matter-logo-name-small.svg";
import { useNavigate, useParams } from "react-router-dom";
import { InitialContext } from "../../Utils/Context/InitailContext";
import background from "../../assets/background_first.png";
import UseHeightHook from "../../Utils/Hooks/UseHeightHook";

function Login() {
  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
  };
  const textStyle = {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "1.5rem",
      lineHeight: "1.8rem",
      fontFamily: "Nexa Bold",
      weight: "700",
      color: "#0C1F21",
    },
    input: {
      fontSize: "1.5rem",
      fontFamily: "Nexa Bold",
      fontWeight: 400,
      color: "#0C1F21",
    },
  };

  const windowHeight = UseHeightHook();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [inProcess, setInProcess] = useState(false);
  const history = useNavigate();
  let { key } = useParams();
  const initial = useContext(InitialContext);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    if (email && password) {
      event.preventDefault();
      setInProcess(true);
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      await userSignIn(formData);
    } else {
      initial.setSnacky({
        color: "error",
        message: "All field are required",
        open: true,
      });
    }
  };

  function saveDataToLocalStorage(key, data) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, JSON.stringify(data));
        resolve("Data saved successfully to localStorage.");
      } catch (error) {
        reject("Error saving data to localStorage: " + error.message);
      }
    });
  }
  const userSignIn = async (formData) => {
    try {
      const response = await loginUser(formData);
      if (response.status === 200) {
        initial.setSnacky({
          color: "success",
          message: "User Logged In Successfully",
          open: true,
        });
        saveDataToLocalStorage("user", response)
          .then((result) => {
            console.log(result);
            setInProcess(false);
            history("/");
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setInProcess(false);
        if (response?.response?.data["non_field_errors"]) {
          initial.setSnacky({
            color: "error",
            message: response?.response?.data?.non_field_errors[0],
            open: true,
          });
        } else if (response?.response?.data["email"]) {
          initial.setSnacky({
            color: "error",
            message: response?.response?.data?.email[0],
            open: true,
          });
        } else if (response?.response?.data["password"]) {
          initial.setSnacky({
            color: "error",
            message: response?.response?.data?.password[0],
            open: true,
          });
        } else {
          initial.setSnacky({
            color: "error",
            message: "Something went wrong",
            open: true,
          });
        }
      }
    } catch (error) {
      initial.setSnacky({
        color: "error",
        message: "An Error Occured Contact Admin",
        open: true,
      });
      setInProcess(false);
    }
  };
  return (
    <div style={backgroundStyle}>
      <Grid
        className={classes.landing_logo}
        container
        style={{ minHeight: "20%", width: "80%" }}
      >
        <img src={MatterLogo} alt="matter-logo" />
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.flex_center}>
          <Box
            className={classes.flex_center}
            sx={
              windowHeight <= 832
                ? { flexDirection: "column", gap: "3.6rem" }
                : { flexDirection: "column", gap: "7.6rem" }
            }
          >
            <p className={classes.page_name}>Admin Login</p>
            <Box
              className={classes.flex_center}
              sx={{ flexDirection: "column", width: "40vw", gap: "4.67rem" }}
            >
              <TextField
                sx={textStyle}
                id="standard-basic"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                type="email"
                fullWidth={true}
                variant="standard"
              />
              <TextField
                sx={textStyle}
                id="standard-basic"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                fullWidth={true}
                variant="standard"
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        className={classes.text_padding}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "23.5%" }}
      >
        <Grid>
          <Box>
            <button
              className={classes.nxt_btn}
              onClick={handleSubmit}
              disabled={inProcess}
            >
              Login
            </button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
