import React, { useState } from "react";
import "./index.css";

const MultipleChoice = (props) => {
  const [checkedParagraphId, setCheckedParagraphId] = useState(null);
  const handleCheckboxChange = (value) => {
    if (checkedParagraphId === value) {
      setCheckedParagraphId(null);
    } else {
      setCheckedParagraphId(value);
    }
    if (props.questionIndex !== undefined) {
      props.checkBoxChange(value, props.questionIndex, props.categoryIndex);
    } else {
      props.checkBoxChange(value);
    }
  };

  return (
    <>
      <div key={props.val.id}>
        <label className="checkBox_flex">
          <input
            type="checkbox"
            className="mc-sty"
            onChange={() => handleCheckboxChange(props.val.id)}
            checked={props.answer && props.answer.includes(props.val.id)}
          />
          <span
            className={
              checkedParagraphId === props.val.id
                ? "checkbox_label_checked"
                : "checkbox_label"
            }
          >
            {props.labelText}
          </span>
        </label>
      </div>
    </>
  );
};

export default MultipleChoice;
