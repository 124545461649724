import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import ResponseSlice from "./Reducers/ResponseSlice";
import ConstantSlice from "./Reducers/ConstantSlice";
import UserSlice from "./Reducers/UserSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};
const reducer = combineReducers({
  initialResponse: ResponseSlice,
  constantData: ConstantSlice,
  userData: UserSlice,
});

const persist_reducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persist_reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(ResponseApi.middleware)
});
setupListeners(store.dispatch);
