import Chart from "react-apexcharts";
import { useState } from "react";
import { constantColors } from "../../Utils/Constants/constants";

const CircularProgress = (props) => {
  // const [series, setSeries] = useState([76, 10, 61, 90, 12, 90]);

  // const updateElement = (index, newValue) => {
  //     setSeries((prevArray) => {
  //         const updatedArray = [...prevArray];
  //         updatedArray[index] = updatedArray[index] + newValue;
  //         return updatedArray;
  //     });
  // };

  // const handleUpdate = () => {
  //     updateElement(1, 5);
  // };

  const options = {
    chart: {
      height: 500,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          // margin: ,
          size: "30%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          // name: {
          //   fontSize: '20px',
          //   show: true,
          //   formatter: function (seriesName) {
          //     console.log(seriesName);
          //     return seriesName;
          //   },
          // },
          total: {
            show: true,
            label: "Progress",
            formatter: function (w) {
              const sumWithInitial = w.config.series.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              );
              return `${Math.round(
                (sumWithInitial / (w.config.series.length * 100)) * 100
              )}%`;
            },
          },
        },
      },
    },
    labels: props?.labels,
    colors: constantColors,
    legend: {
      show: true,
      floating: true,
      fontSize: "16px",
      position: "left",
      offsetX: 10,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },

      formatter: function (seriesName, opts) {
        // console.log(seriesName);
        return (
          seriesName +
          ":  " +
          Math.round(opts.w.globals.series[opts.seriesIndex]) +
          "%"
        );
      },
      itemMargin: {
        vertical: 3,
      },
    },
  };
  // console.log(props.data, props.labels, props)
  return (
    <>
      <h2>Skewed Percentage</h2>
      {/*<button onClick={handleUpdate}>click me</button>*/}
      <Chart
        options={options}
        series={props.series}
        type="radialBar"
        height={390}
      />
    </>
  );
};

export default CircularProgress;
