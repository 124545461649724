import instance from "../../Utils/Axios/instance";

export const EditFormApi = async (id, form) => {
  try {
    await instance.put(`/form/${id}/`, form);
    return true;
  } catch (error) {
    // console.log(error);
    return false;
  }
};

export const DuplicateFormApi = async (id, form) => {
  try {
    await instance.post(`/duplicate_form/${id}/`, form);
    return true;
  } catch (error) {
    // console.log(error);
    return false;
  }
};
