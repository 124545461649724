import React, { useState, useEffect } from "react";
import style from "./SecondPage.module.css";
import Logo from "../../assets/logo.svg";
import notificationImage from "../../assets//notification.svg";
import bellImage from "../../assets/bell.svg";
import avatarImage from "../../assets/avatar.png";
import circlesImage from "../../assets/circles.svg";
import arrowImage from "../../assets/arrow.svg";

function SecondPage() {
  const [selectedTextValues, setSelectedTextValues] = useState([]);
  const goals = {
    business: [
      {
        name: "raise_money",
        text: "Raise Money",
      },
      {
        name: "sales_partners",
        text: "Close sales and find partners",
      },
      {
        name: "financial_business",
        text: "Develop Financial and bussiness model",
      },
      {
        name: "strategy",
        text: "Develop brand and go-to-market strategy",
      },
    ],
    product: [
      {
        name: "approval",
        text: "Obtain regulatory or legal approval",
      },
      {
        name: "proposition_market_fit",
        text: "Define value proposition and product-market fit",
      },
      {
        name: "design_develop",
        text: "Design and develop product(s)",
      },
    ],
    team: [
      {
        name: "leader",
        text: "Become a better leader",
      },
      {
        name: "team",
        text: "Hire a solid team",
      },
      {
        name: "advisory",
        text: "Build an advisory board",
      },
    ],
  };

  const handleCheckboxChange = (event) => {
    const checkboxName = event.target.name;
    const textValue = event.target.nextSibling.textContent; // Get the label text

    if (event.target.checked) {
      // If checkbox is checked, add textValue to the state
      setSelectedTextValues((prevValues) => [...prevValues, textValue]);
    } else {
      // If checkbox is unchecked, remove textValue from the state
      setSelectedTextValues((prevValues) =>
        prevValues.filter((value) => value !== textValue)
      );
    }
  };

  useEffect(() => {
    console.log(selectedTextValues);
  }, [selectedTextValues]);

  return (
    <>
      <section className={`${style.header_section} `}>
        <div className="row m-0">
          <nav
            className={`${style.navbar} navbar navbar-expand-lg navbar-light px-3`}
          >
            <div className="container-fluid">
              <a className="navbar-brand" href="">
                <img className={`${style.cstm_logo}`} src={Logo} alt="" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarText">
                <ul
                  className={`${style.navbar_nav} navbar-nav me-auto mb-2 mb-lg-0`}
                >
                  <li className="nav-item">
                    <a
                      className={`${style.nav_link} nav-link`}
                      aria-current="page"
                      href="#"
                    >
                      Your Result
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`${style.nav_link} nav-link`} href="#">
                      Nav
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`${style.nav_link} nav-link`} href="#">
                      Nav
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`${style.nav_link} nav-link`} href="#">
                      Nav
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`${style.nav_link} nav-link`} href="#">
                      Nav
                    </a>
                  </li>
                </ul>
              </div>

              <div className="d-flex">
                <div className="position-relative">
                  {" "}
                  <img
                    src={bellImage}
                    className={`${style.custom_notification} p-2 mx-1`}
                    alt=""
                  />
                  <span className={`${style.cstm_badge} badge`}>9</span>
                </div>
                <div className="position-relative">
                  {" "}
                  <img
                    src={notificationImage}
                    className={`${style.custom_notification} p-2 mx-1`}
                    alt=""
                  />
                  <span className={`${style.cstm_badge} badge`}>9</span>
                </div>

                <div className={`${style.cstm_profile} position-relative`}>
                  <img src={avatarImage} alt="" />
                  &nbsp;
                  <span className={`${style.user_name}`}>Katie Deloso</span>
                  <span className={`${style.user_des}`}>CEO</span>
                </div>
                <div className="dropdown">
                  <button
                    className={`${style.profile_dropdown} navbar_profile_dropdown btn dropdown-toggle`}
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></button>
                  <ul
                    className={`${style.profile_dropdown_menu} dropdown-menu`}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="">
                        <img src={avatarImage} alt="" /> Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>

      <section>
        <div className={`${style.goal_step_section}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9 ps-0">
                <div className={`${style.goal_steps} `}>
                  <ul className="ps-0 mb-0 mt-3">
                    <li className="ps-0">
                      1.View your results
                      <span className={`${style.list_divider} `}></span>
                    </li>
                    <li className="ps-0">
                      2.Set Your Goals
                      <span className={`${style.list_divider} `}></span>
                    </li>
                    <li className="ps-0">
                      3.See Recommendations
                      <span className={`${style.list_divider} `}></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 pe-0">
                <button
                  type="button"
                  className={`${style.set_your_goal_btn} btn`}
                >
                  <img src={circlesImage} alt="" />
                  Set your goals
                  <img src={arrowImage} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={`container my-5`}>
        <div className={`row`}>
          <div className={`col-sm-12`}>
            <div className={`d-flex flex-row mb-3`}>
              <div>
                <img src="/assets/hexagonal.png" width="80px" alt="" />
              </div>
              <div className={`col-sm-7 mx-2 my-3`}>
                <h1 className={`h1 ${style.head_1}`}>Set Your Goals</h1>
                <p className={`h5 ${style.para_1}`}>
                  Prioritize what you'd like to learn on over the next 3-6
                  months
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`container px-4`}>
        <div className={`row gx-4 row-sm`}>
          <div className={`col col-6 col-sm`}>
            <div className={`p-4 border  ${style.box}`}>
              <h2 className={`my-0 ${style.head_2}`}>Select Goals</h2>
              <p className={`my-3 ${style.para_2}`}>
                Select which goals you'd like to work on over the next 3
                <br />
                -6 months.
              </p>
              <hr />
              <ul className={`list-group`}>
                <p className={`h4 ${style.my_label}`}>Business</p>
                {goals?.business.map((x) => (
                  <li className={`list-group-item ${style.li_css}`}>
                    <input
                      className={`form-check-input ${style.custom_checkbox}`}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      name={`${x.name}`}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className={`form-check-label mx-2`}
                      for="flexCheckDefault"
                    >
                      {x.text}
                    </label>
                  </li>
                ))}
              </ul>
              <hr />
              <ul className={`list-group`}>
                <p className={`h4 ${style.my_label}`}>Product</p>
                {goals?.product.map((x) => (
                  <li className={`list-group-item ${style.li_css}`}>
                    <input
                      className={`form-check-input ${style.custom_checkbox}`}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      name={`${x.name}`}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className={`form-check-label mx-2`}
                      for="flexCheckDefault"
                    >
                      {x.text}
                    </label>
                  </li>
                ))}
              </ul>
              <hr />
              <ul className={`list-group`}>
                <p className={`h4 ${style.my_label}`}>Team</p>
                {goals?.team.map((x) => (
                  <li className={`list-group-item ${style.li_css}`}>
                    <input
                      className={`form-check-input ${style.custom_checkbox}`}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      name={`${x.name}`}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className={`form-check-label mx-2`}
                      for="flexCheckDefault"
                    >
                      {x.text}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className={`col col-7 col-sm px-4 position-relative`}>
            <div className={`p-3 border rounded-3 ${style.extra}`}>
              {selectedTextValues.length > 0 && (
                <>
                  <h2 className={`w-100 my-3 ${style.second_head}`}>
                    Are these prioritized correctly?
                  </h2>
                  <p className={`w-100 my-3 ${style.para_4}`}>
                    Optionally you can change the priority below. This will
                    helps us provide better content recommendations.
                  </p>
                  <h3>Your Top Goal</h3>
                </>
              )}
              {selectedTextValues.length > 0 ? (
                selectedTextValues.map((x, i) => (
                  <div
                    key={i}
                    className={`card w-76 p-2 ${style.ln_25}`}
                    draggable="true" // Make the card draggable
                    onDragStart={(e) => {
                      e.dataTransfer.setData("text/plain", i); // Set the data to be transferred (index)
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      const sourceIndex = e.dataTransfer.getData("text/plain"); // Get the source index
                      const targetIndex = i; // Get the target index
                      const updatedTextValues = [...selectedTextValues];

                      // Swap the positions of cards in the array
                      const temp = updatedTextValues[sourceIndex];
                      updatedTextValues[sourceIndex] =
                        updatedTextValues[targetIndex];
                      updatedTextValues[targetIndex] = temp;

                      setSelectedTextValues(updatedTextValues);
                    }}
                  >
                    <div className={`card-body  ${style.flex_3}`}>
                      <div className={`mx-2 py-1 mt-2`}>
                        <div className={`my-0 invisible  ${style.carret_up}`}>
                          <svg
                            width="11"
                            height="6"
                            viewBox="0 0 11 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.62907 5.68933L1.25007 5.68933C1.10175 5.6893 0.956779 5.6453 0.833472 5.56288C0.710165 5.48047 0.61406 5.36335 0.557308 5.22632C0.500555 5.0893 0.485703 4.93852 0.514628 4.79306C0.543554 4.64759 0.61496 4.51397 0.719816 4.40908L4.90932 0.219591C5.04996 0.0789881 5.24069 9.53674e-07 5.43957 9.53674e-07C5.63844 9.53674e-07 5.82917 0.0789881 5.96982 0.219591L10.1593 4.40908C10.2642 4.51397 10.3356 4.64759 10.3645 4.79306C10.3934 4.93852 10.3786 5.0893 10.3218 5.22632C10.2651 5.36335 10.169 5.48047 10.0457 5.56288C9.92235 5.6453 9.77738 5.6893 9.62907 5.68933Z"
                              fill="#929999"
                            />
                          </svg>
                        </div>

                        <div className={`rounded-circle   ${style.round}`}>
                          {i < 10 ? `0${i + 1}` : i + 1}
                        </div>
                        <div className={`my-0 ${style.carret_down}`}>
                          <svg
                            width="11"
                            height="7"
                            viewBox="0 0 11 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.24984 0.75H9.62884C9.77715 0.750032 9.92213 0.794035 10.0454 0.876448C10.1687 0.958861 10.2648 1.07598 10.3216 1.21301C10.3784 1.35003 10.3932 1.50081 10.3643 1.64627C10.3354 1.79174 10.2639 1.92536 10.1591 2.03025L5.96959 6.21974C5.82894 6.36034 5.63821 6.43933 5.43934 6.43933C5.24047 6.43933 5.04974 6.36034 4.90909 6.21974L0.71959 2.03025C0.614733 1.92536 0.543328 1.79174 0.514403 1.64627C0.485477 1.50081 0.500329 1.35003 0.557082 1.21301C0.613834 1.07598 0.709939 0.958861 0.833246 0.876448C0.956554 0.794035 1.10153 0.750032 1.24984 0.75Z"
                              fill="#929999"
                            />
                          </svg>
                        </div>
                      </div>

                      <div className={`h5 ${style.seperate}`}>
                        <h5 className={`card-title  ${style.head}`}>{x}</h5>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={`card w-76 ${style.card}`}>
                  <div className={`card-body p-0 position-relative`}>
                    <img
                      src="/assets/arrow.svg"
                      alt=""
                      className={`${style.arrow_img}`}
                    />
                    <div className={`h5`}>
                      <h5 className={`card-title ${style.card_title}`}>
                        To get started, select goals
                      </h5>
                      <p className={`${style.card_text}`}>
                        On the right, choose which goals you’d like to work on
                        over the next 3 - 6 months. We’ll add them here.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondPage;
