export const setCookie = () => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7); // Set expiration to 7 days from now

  const cookieValue = "data-to-persist";
  document.cookie = `myCookie=${encodeURIComponent(
    cookieValue
  )}; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = `myCookieExpires=${encodeURIComponent(
    expirationDate.toUTCString()
  )}; expires=${expirationDate.toUTCString()}; path=/`;
};
export const handleExpiredCookie = async (persistor) => {
  persistor.purge().then(() => {
    console.log("purged");
    console.log("Cookie has expired");
    setCookie();
  });
};

export const checkCookie = (persistor) => {
  const cookies = document.cookie.split(";");
  let myCookieValue;
  let myCookieExpiration;

  cookies.forEach((cookie) => {
    const [name, value] = cookie.trim().split("=");
    if (name === "myCookie") {
      myCookieValue = decodeURIComponent(value);
    } else if (name === "myCookieExpires") {
      myCookieExpiration = decodeURIComponent(value);
    }
  });

  if (myCookieValue) {
    const expirationDate = new Date(myCookieExpiration);

    const currentDate = new Date();
    if (expirationDate.getDate() < currentDate.getDate()) {
      handleExpiredCookie(persistor);
    }
  } else {
    setCookie();
  }
};
