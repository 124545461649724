import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  isSuccess: false,
  newData: null,
  questionIndex: 0,
  categoryIndex: 0,
  isLoading: true,
};

export const responseSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    setResponse: (state, action) => {
      action.payload.categories = action.payload.categories.map(
        (category, catIndex) => ({
          ...category,
          category_id: category.id,
          questions: category.questions.map((question, questionIndex) => ({
            ...question,
            question_id: question.id,
            answers: [],
            answer_text: "",
            error: true,
            selected: false,
            character_limit: question.question_type === "OT" ? 200 : 0,
          })),
        })
      );
      state.data = action.payload;
      state.isSuccess = true;
      state.isLoading = false;
    },
    setOpenTextChanges: (state, action) => {
      const { questionIndex, categoryIndex, value, data, chars } =
        action.payload;
      state.data = data;
      let tempQuestionObject =
        state.data.categories[categoryIndex].questions[questionIndex];
      tempQuestionObject.error = false;
      tempQuestionObject.answer_text = value;
      tempQuestionObject.selected = true;
      tempQuestionObject.character_limit = chars;
    },
    setRadioChanges: (state, action) => {
      const { questionIndex, categoryIndex, value, data } = action.payload;
      state.data = data;
      let tempQuestionObject =
        state.data.categories[categoryIndex].questions[questionIndex];
      tempQuestionObject.error = false;
      tempQuestionObject.selected = true;
      if (tempQuestionObject.answers.length !== 0) {
        tempQuestionObject.answers.pop();
      }
      tempQuestionObject.answers.push(value);
    },
    setMultipleChoiceChanges: (state, action) => {
      const { questionIndex, categoryIndex, value, data } = action.payload;
      state.data = data;
      let tempQuestionObject =
        state.data.categories[categoryIndex].questions[questionIndex];
      tempQuestionObject.error = false;
      tempQuestionObject.selected = true;
      if (tempQuestionObject.answers.includes(value)) {
        tempQuestionObject.answers = tempQuestionObject.answers.filter(
          (val) => val !== value
        );
        if (tempQuestionObject.answers.length === 0) {
          tempQuestionObject.error = true;
          tempQuestionObject.selected = false;
        }
      } else {
        tempQuestionObject.answers.push(value);
      }
    },
    updateQuestionIndex: (state, action) => {
      state.questionIndex = action.payload;
    },
    updateCategoryIndex: (state, action) => {
      state.categoryIndex = action.payload;
    },
    clearRedux: (state, action) => {
      console.log("here", action.payload);
      state.data = undefined;
      state.isSuccess = false;
      state.newData = undefined;
      state.questionIndex = 0;
      state.categoryIndex = 0;
    },
  },
});

export const {
  updateQuestionIndex,
  updateCategoryIndex,
  setResponse,
  setOpenTextChanges,
  setRadioChanges,
  setMultipleChoiceChanges,
  clearRedux,
} = responseSlice.actions;
export default responseSlice.reducer;
