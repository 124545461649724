import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import MatterLogo from "../../assets/Matter-logo-name-small.svg";
import classes from "./Register.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { InitialContext } from "../../Utils/Context/InitailContext";
import { createStartup } from "../../Apis/CreateStartUpApi";
import background from "../../assets/background_first.png";
import { setUserData } from "../../Store/Reducers/UserSlice";
import { useDispatch } from "react-redux";
import useHeightCheck from "../../Utils/Hooks/UseHeightHook";
import { AnalyticEvents } from "../../Utils/Helpers/helpers";

// import { makeStyles } from '@material-ui/core/styles';

function Register() {
  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
  };
  const textStyle = {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "1.5rem",
      lineHeight: "1.8rem",
      fontFamily: "Nexa Bold",
      weight: "700",
      color: "#0C1F21",
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      marginTop: "43px",
    },
    input: {
      fontSize: "1.5rem",
      fontFamily: "Nexa Bold",
      fontWeight: 400,
      color: "#0C1F21",
    },
  };
  const textStyleSmall = {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "1rem",
      lineHeight: "1.2rem",
      fontFamily: "Nexa Bold",
      weight: "700",
      color: "#0C1F21",
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      marginTop: "43px",
    },
    input: {
      fontSize: "1rem",
      fontFamily: "Nexa Bold",
      fontWeight: 400,
      color: "#0C1F21",
    },
  };

  const windowHeight = useHeightCheck();

  const [textFieldStyles, setTextFieldStyles] = useState("2rem");
  useEffect(() => {
    if (windowHeight >= 832) {
      setTextFieldStyles("4rem");
    } else if (windowHeight >= 620) {
      setTextFieldStyles("2rem");
    } else if (windowHeight >= 500) {
      setTextFieldStyles("1rem");
    }
  }, [windowHeight]);

  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [inProcess, setInProcess] = useState(false);
  const history = useNavigate();
  let { key } = useParams();
  const initial = useContext(InitialContext);

  const [errors, setErrors] = useState({});
  const handleEmail = (event) => {
    const { name, value } = event.target;
    setEmail(value);
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = async (event) => {
    let hasError = false;
    if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
      hasError = true;
    }
    if (!fullName.replaceAll(" ", "").length) {
      errors.fullName = "Invalid user name";
      hasError = true;
    }
    if (!hasError && companyName) {
      event.preventDefault();
      setInProcess(true);
      const formData = new FormData();
      formData.append("username", fullName);
      formData.append("company_name", companyName);
      formData.append("email", email);
      let payload = {
        username: fullName,
        company_name: companyName,
        email: email,
      };
      await registerUser(payload);
    } else {
      initial.setSnacky({
        color: "error",
        message: "All field are required",
        open: true,
      });
    }
  };
  const registerUser = async (payload) => {
    const response = await createStartup(payload);
    if (response.success) {
      initial.setSnacky({
        color: "success",
        message: "Startup Registered Successfully",
        open: true,
      });
      dispatch(setUserData(response));
      let tempObject = {
        first_name: response.first_name,
        last_name: response.last_name,
        id: response.id,
        is_staff: response.is_staff,
        startup_id: response.startup_id,
      };
      AnalyticEvents("user registration Event", JSON.stringify(tempObject));
      localStorage.setItem("startup", response);
      history(`/register/welcome/${key}`);
      setInProcess(false);
    } else {
      initial.setSnacky({
        color: "error",
        message: "An error Occurred Please contact Admin",
        open: true,
      });
      setInProcess(false);
    }
  };
  return (
    <div style={backgroundStyle}>
      <Grid className={classes.landing_logo} container sx={{ width: "80%" }}>
        <img
          src={MatterLogo}
          alt="matter-logo"
          className={classes.landing_logo_img}
        />
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.register_main_form_div}
      >
        <Box className={classes.flex_center}>
          <Box className={classes.flex_center} sx={{ flexDirection: "column" }}>
            <Box className={classes.main_wrapper}>
              <p className={classes.text_register_form}>
                First things first...
              </p>
            </Box>
            <Box
              className={classes.register_form_div}
              sx={{ flexDirection: "column", gap: textFieldStyles }}
            >
              <TextField
                sx={windowHeight <= 832 ? textStyleSmall : textStyle}
                id="standard-basic"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Your Name"
                fullWidth={true}
                variant="standard"
                name="fullName"
                error={!!errors.fullName}
                helperText={errors.fullName}
              />
              <TextField
                sx={windowHeight <= 832 ? textStyleSmall : textStyle}
                id="standard-basic"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Company Name"
                fullWidth={true}
                variant="standard"
                name="companyName"
              />
              <TextField
                sx={windowHeight <= 832 ? textStyleSmall : textStyle}
                id="standard-basic"
                value={email}
                name="email"
                onChange={handleEmail}
                placeholder="Email Address"
                type="email"
                fullWidth
                variant="standard"
                error={!!errors.email}
                helperText={errors.email}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        className={classes.last_section}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid>
          <Box>
            <button
              className={classes.next_checked}
              onClick={validateForm}
              disabled={inProcess}
            >
              Next
            </button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Register;
