import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { InitialContext } from "../../Utils/Context/InitailContext";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { getResponsesOfForm } from "../../Apis/FormResponse/GetFormResponses";
import { DownloadCSV } from "../../Apis/FormResponse/DownloadCSV";
import SkeletonLoading from "../../Components/Skeleton";

const FormResponses = () => {
  const [responses, setResponses] = useState([]);
  const [dataInCSV, setDataInCSV] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = useState("");
  const [snackLoading, setSnackLoading] = React.useState(true);

  const initial = useContext(InitialContext);
  const history = useNavigate();
  const { key } = useParams();
  const csvLink = useRef();

  const handlePopup = async (key, startup_name) => {
    setLoading(true);
    initial.setSnacky({
      loading: true,
    });

    const data = await DownloadCSV(key);

    if (data.error) {
      setLoading(false);
      initial.setSnacky({
        color: "error",
        message: `${data.message}`,
        open: true,
        loading: false,
      });
    } else {
      setDataInCSV(data);
      setName(startup_name);
      setTimeout(() => {
        csvLink.current.click();
        setLoading(false);
        initial.setSnacky({
          color: "info",
          message: "Download successfully.",
          open: true,
          loading: false,
        });
      }, 2000);
    }
  };

  const getAllResponsesToForm = async () => {
    const data = await getResponsesOfForm(key);
    if (data.error) {
      initial.setSnacky({
        color: "error",
        message: data.message,
        open: true,
      });
      setSnackLoading(false);
    } else {
      setResponses(data);
      setSnackLoading(false);
    }
  };

  useEffect(() => {
    getAllResponsesToForm();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item md={8} className="mx-auto mt-3 ">
          {snackLoading ? (
            <SkeletonLoading
              class="px-4 pb-4 pt-3 my-2 headind__card"
              height="25vh"
            />
          ) : (
            <>
              {responses.length === 0 ? (
                <Paper
                  className="px-4 pb-4 pt-3 my-2 headind__card"
                  elevation={5}
                >
                  No records found
                </Paper>
              ) : (
                responses?.map((response, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Paper
                        className="px-4 pb-4 pt-3 my-2 headind__card"
                        elevation={5}
                        key={response.id}
                      >
                        <Grid container>
                          <Grid item xs={11}>
                            <Typography display="block" variant="h5">
                              Startup Name: {response.startup_name}
                            </Typography>
                            <Typography display="block" variant="h6">
                              Email: {response.user_email}
                            </Typography>
                            <Typography display="block">
                              Submitted at:
                              {dayjs(response.created_at).format(
                                "DD-MM-YYYY h:mm:ss A"
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className="d-flex justify-content-center flex-column"
                          >
                            <Tooltip title="Download CSV" placement="right">
                              <IconButton
                                disabled={loading}
                                onClick={() =>
                                  handlePopup(
                                    response.key,
                                    response.startup_name
                                  )
                                }
                                aria-label="csv"
                              >
                                <DownloadIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="See result"
                              placement="right"
                              onClick={() => history(`/result/${response.key}`)}
                            >
                              <IconButton aria-label="All responses">
                                <ArrowRightAltIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="See Charts"
                              placement="right"
                              onClick={() =>
                                history(`/scorecard/${response.key}/results`)
                              }
                            >
                              <IconButton aria-label="All responses">
                                <AssignmentIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            {dataInCSV && (
                              <a
                                style={{ display: "none" }}
                                ref={csvLink}
                                href={`data:text/csv;charset=utf-8,${escape(
                                  dataInCSV
                                )}`}
                                download={`${name}.csv`}
                              >
                                download
                              </a>
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </React.Fragment>
                  );
                })
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FormResponses;
