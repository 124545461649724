import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    email: "",
    first_name: "",
    id: 0,
    is_staff: false,
    last_name: "",
    startup_id: 0,
  },
};

export const userSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      console.log(action.payload);
      state.userData = action.payload;
    },
  },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;
