import axios from "axios";
import { BaseUrl } from "../Utils/BaseUrl/BaseUrl";

export const getOneForm = async (id) => {
  // TODO add error handling here
  try {
    const { data } = await axios.get(`${BaseUrl}/form/${id}/`);
    // const { data } = await axios.get(`${BaseUrl}/data`)
    if (data) {
      return data;
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      return error.response.status;
    }
  }
};

export const postOneForm = async (payload) => {
  try {
    const { data } = await axios.post(`${BaseUrl}/form/answers/`, payload);
    if (data) {
      return data;
    }
  } catch (error) {
    console.log(error);
    error.error = true;
    return error;
  }
};
