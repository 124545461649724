import React from "react";
import { Container, Grid, Paper, TextField } from "@mui/material";
import "./index.css";
import Fields from "./Fields";
import AddQuestion from "../../Components/Form/AddQuestion";
import Box from "@mui/material/Box";

function Question({
  sectionIndex,
  questions,
  setSections,
  sections,
  deleteQuestion,
  deleteOption,
  setQuestionCount,
}) {
  const addOption = (questionId) => {
    let item = sections[sectionIndex];
    item.questions.map((x, index) => {
      if (questionId === index) {
        x.options.push({ option: "" });
      }
      return x;
    });
    let newSections = [...sections];
    newSections[sectionIndex] = item;
    setSections(newSections);
  };

  const addQuestion = () => {
    let item = sections[sectionIndex];
    let test = {
      question_text: "",
      question_description: "",
      type: "single_choice",
      q_type: "normal",
      is_required: true,
      is_horizontal: false,
      options: [
        {
          option: "",
        },
      ],
    };
    item.questions.push(test);
    let newSections = [...sections];
    newSections[sectionIndex] = item;
    setSections(newSections);
    setQuestionCount((prev) => prev + 1);
  };

  const handleOptionValue = (e, qIndex, position) => {
    const value = e.target.value;
    const name = e.target.name;
    let item = sections[sectionIndex];
    let question = {};
    question = item.questions[qIndex];
    question.options[position][name] = value;
    let newQuestions = [...item.questions];
    newQuestions[qIndex] = question;
    let newSections = [...sections];
    newSections[sectionIndex] = item;
    setSections(newSections);
  };

  const handleQuestionValue = (name, value, position) => {
    let item = sections[sectionIndex];
    let question = {};
    question = item.questions[position];
    question[name] = value;
    let newQuestions = [...item.questions];
    newQuestions[position] = question;
    let newSections = [...sections];
    newSections[sectionIndex] = item;
    setSections(newSections);
  };

  const handleQuestion = (e, position) => {
    const name = e.target.name;
    const value = e.target.value;
    handleQuestionValue(name, value, position);
  };

  const handleQuestionType = (e, position) => {
    const name = e.target.name;
    const value = e.target.value;
    handleQuestionValue(name, value, position);
  };

  const handleType = (e, position) => {
    const name = e.target.name;
    const value = e.target.value;
    handleQuestionValue(name, value, position);
    if (value === "text") {
      let item = questions[position];
      item["options"] = [];
      let newQuestions = [...questions];
      newQuestions[position] = item;
    }
  };

  const handleChange = (position, val) => {
    let item = sections[sectionIndex];
    let question = {};
    question = item.questions[position];
    question[val] = !question[val];
    let newQuestions = [...item.questions];
    newQuestions[position] = question;
    let newSections = [...sections];
    newSections[sectionIndex] = item;
    setSections(newSections);
  };

  const handleCategory = (e) => {
    let item = sections[sectionIndex];
    item[e.target.name] = e.target.value;
    let newSections = [...sections];
    newSections[sectionIndex] = item;
    setSections(newSections);
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={8} className="mx-auto">
            <Paper className="px-4 pb-4 pt-3 headind__card" elevation={5}>
              <TextField
                name="form_category"
                className="form__cat"
                placeholder="Category"
                variant="standard"
                onChange={(e) => handleCategory(e)}
                fullWidth
              />
              <TextField
                name="category_description"
                className="form__cat_desc mb-2"
                placeholder="Description"
                variant="standard"
                onChange={(e) => handleCategory(e)}
                fullWidth
              />
              <TextField
                variant="standard"
                type="number"
                name="category_weightage"
                placeholder="Enter weightage"
                onChange={(e) => handleCategory(e)}
                fullWidth
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                }}
              />
            </Paper>
          </Grid>
        </Grid>
        {questions.map((x, index) => (
          <Grid key={index} container>
            <Grid item xs={8} className="mx-auto mt-3">
              <Fields
                x={x}
                key={index + "new"}
                handleChange={handleChange}
                formIndex={index}
                addOption={addOption}
                handleQuestion={handleQuestion}
                handleQuestionType={handleQuestionType}
                handleType={handleType}
                handleOptionValue={handleOptionValue}
                sectionIndex={sectionIndex}
                deleteQuestion={deleteQuestion}
                deleteOption={deleteOption}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="flex-end" direction="row">
          <Grid item>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box>
                <AddQuestion addQuestion={addQuestion} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Question;
