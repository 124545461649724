import instance from "../../Utils/Axios/instance";

export const DownloadCSV = async (key) => {
  try {
    const { data } = await instance.post("/response/csv", {
      form_response_key: key,
    });
    if (data) {
      return data;
    }
  } catch (error) {
    error.error = true;
    return error;
  }
};
